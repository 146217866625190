<template>
  <main class="specialist">
    <div class="specialist__content">
      <div class="specialist__head wrapper">
        <div class="specialist__left">
          <CBreadcrumbs :parent-sections="breadcrumbs" :current-page-name="doctorsInfo?.title" />

          <h1 class="specialist__left-title">{{ doctorsInfo?.title }}</h1>
          <div v-if="doctorsInfo?.experience" class="specialist__left-desc">
            Стаж работы – {{ doctorsInfo?.experience }}
          </div>
          <div class="specialist__left-txt">
            {{ doctorsInfo.description }}
          </div>
          <div class="specialist__left-button">
            <CButtonTrigger
              button-title="Записаться"
              :button-trigger="{
                type: 'form',
                content: 'doctorsForm',
              }"
            />
          </div>
        </div>
        <div class="specialist__head-img">
          <div v-if="doctorsInfo?.link" class="video" @click="openMediaModal(0)"></div>
          <UIPictureTag
            :image-original="`${url}${doctorsInfo?.image.source}`"
            :image-webp="`${url}${doctorsInfo?.image.webp}`"
          />
        </div>
      </div>

      <section v-if="tabsNames?.length > 0" class="tabs">
        <div class="tabs__wrapper">
          <div class="tabs__header wrapper">
            <div class="tabs__btn" :class="{ active: activeTab === 0 }" @click="activeTab = 0">
              <span>O враче</span>
            </div>
            <div
              v-for="(item, index) in tabsNames"
              :key="index + 1"
              class="tabs__btn"
              :class="{ active: activeTab === index + 1 }"
              @click="activeTab = index + 1"
            >
              <span>
                {{ item }}
              </span>
            </div>
          </div>
          <div class="tabs__content">
            <div
              v-show="activeTab === 0"
              class="tabs__content-item text-tab"
              :class="{ dropdown: isShowTab }"
            >
              <button class="button text-tab__btn" @click="isShowTab = !isShowTab">
                <NuxtIcon name="IconArrowThinRight" />
              </button>
              <template v-for="tabContent in data.tabs">
                <template v-for="(block, index) in tabContent.blocks" :key="block.id">
                  <Component
                    v-bind="getBlocksProps(block, index)"
                    :is="articleBlocks[block.type]"
                    v-if="articleBlocks[block.type]"
                    :class="{ 'mt-0--i': block.type === 'BlockTextForm' }"
                  />
                </template>
              </template>
            </div>
            <div
              v-for="(tabContent, tabIndex) in data.tabs"
              v-show="activeTab === tabIndex + 1"
              :key="tabIndex"
              class="tabs__content-item"
            >
              <template v-for="(block, index) in tabContent.blocks" :key="block.id">
                <Component
                  v-bind="getBlocksProps(block, index)"
                  :is="articleBlocks[block.type]"
                  v-if="articleBlocks[block.type]"
                  :class="{ 'mt-0--i': block.type === 'BlockTextForm' }"
                />
              </template>
            </div>
          </div>
        </div>
      </section>
    </div>

    <COfficeBlock title="Адреса клиник" :offices="transformOffices" />
    <COnlineRegistration
      v-if="isOpenOnlineRegistrationModal"
      :is-open="isOpenOnlineRegistrationModal"
    />
  </main>
</template>

<script setup lang="ts">
import {
  computed,
  createError,
  useRoute,
  ref,
  useArticleBlocks,
  useAsyncData,
  useHead,
  useRuntimeConfig,
  useSeoMeta,
  useState,
  useMediaModal,
  onUnmounted,
  useRouter,
} from '#imports';
import COfficeBlock from '~/components/blocks/officeBlock/COfficeBlock.vue';
import { type ApiDoctorPageResponse, type BlockOffice } from '~/types/pages';
import './styles.scss';
import CBreadcrumbs from '~/components/breadcrumbs/CBreadcrumbs.vue';
import type { ErrorResponse } from '~/server/utils/serverFetch';
import { isErrorResponse } from '~/server/utils/serverError';
import CButtonTrigger from '~/components/buttonTrigger/CButtonTrigger.vue';
import { useSchemaOrg } from '@unhead/schema-org';
import COnlineRegistration from '~/components/blocks/onlineRegistration/COnlineRegistration.vue';

const affiliates = useState<BlockOffice['offices']>('affiliates');
const transformOffices = ref([]);
const route = useRouter().currentRoute.value;
const emit = defineEmits(['error-not-found']);
const slug = computed(() => {
  {
    if (route.path === '/') {
      return 'index';
    }

    if (route.params && route.params.chapters) {
      return (route.params.chapters as string[]).join('/');
    }
  }
});
const { siteUrl } = useRuntimeConfig().public;

const { data, error } = await useAsyncData<ErrorResponse | ApiDoctorPageResponse>(
  'getDoctorDetailContent',
  () => {
    return $fetch('/api/doctors/extended', {
      method: 'GET',
      params: {
        slug: slug.value,
      },
    });
  },
);

if (error.value) {
  throw createError(error.value);
}

const tabsNames = ref();
const doctorsInfo = ref();
const breadcrumbs = ref();
const isOpenOnlineRegistrationModal = ref(false);
const transformBreadcrumbs = data => {
  const items = data.breadcrumbs?.map((el, index) => ({
    id: index,
    slug: el.link,
    title: el.title,
  }));

  if (items?.length >= 2) {
    items?.splice(-1, 1);
  }

  return items;
};

if (data.value) {
  if (!isErrorResponse(data.value)) {
    tabsNames.value = data.value.tabs.map(el => el.name);
    doctorsInfo.value = {
      title: data.value.title,
      image: data.value.image,
      image_seo: data.value.image_seo,
      category: data.value.category,
      extra_categories: data.value.extra_categories,
      link: data.value.link,
      experience: data.value.experience,
      description: data.value.description,
    };

    breadcrumbs.value = transformBreadcrumbs(data.value);

    useHead({
      title: data.value.title || data.value.seo.title,
    });

    useSeoMeta({
      description: data.value.seo.description,
      keywords: data.value.seo.keywords,
      ogTitle: data.value.og.title,
      ogDescription: data.value.og.description,
      ogImage: data.value?.og.image.source
        ? `${siteUrl}${data.value.og.image.source}`
        : '/android-chrome-192x192.png',
      ogUrl: route.fullPath,
    });
  } else {
    emit('error-not-found');
  }
}

if (affiliates.value) {
  transformOffices.value = affiliates.value.map(el => ({
    title: el.title,
    workTime: el.working_time,
    address: el.address,
    phone: el.phone,
    lat: el.latitude,
    lon: el.longitude,
    image: el.image,
    rating: el.blocks[0],
  }));
}

const activeTab = ref(0);

const { articleBlocks, getBlocksProps } = useArticleBlocks();
const url = useRuntimeConfig().public.siteUrl;

const isShowTab = ref(false);

const dataSource = computed(() => ({
  html: `<iframe src="${doctorsInfo.value?.link}?rel=0"
                    frameborder="0"
                    allow="
                     accelerometer;
                     autoplay;
                     clipboard-write;
                     encrypted-media;
                     gyroscope;
                     picture-in-picture;
                     web-share"
                    allowfullscreen>
                   </iframe>`,
}));

const { openMediaModal, destroyMediaModal } = useMediaModal([dataSource.value]);

onUnmounted(() => destroyMediaModal());

useSchemaOrg([
  {
    '@type': 'Dentist',
    name: doctorsInfo.value?.title,
    url: `${url}/${slug.value}`,
    logo: `${url}/android-chrome-192x192.png`,
    image: `${url}${doctorsInfo.value?.image.original}`,
    description: ` Стаж работы – более ${doctorsInfo.value?.experience}. ${doctorsInfo.value.description}`,
  },
]);
</script>

<style scoped></style>
