<script setup lang="ts">
import UIButton from '~/components/UI/button/UIButton.vue';
import UIModal from '~/components/UI/modal/UIModal.vue';
import UIInput from '~/components/UI/input/UIInput.vue';
import {
  ref,
  useAsyncData,
  watch,
  onMounted,
  useRouter,
  useSlugTransform,
  navigateTo,
} from '#imports';
import './styles.scss';
import { useDebounceFn } from '@vueuse/core';
import type { SearchResponse } from '~/types/search';

const router = useRouter();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(props.modelValue);
const emit = defineEmits(['update:modelValue']);

const onCloseModal = () => {
  isOpen.value = false;
  emit('update:modelValue', isOpen.value);
};

const searchQuery = ref<string>('');

const services = ref<SearchResponse['services']>([]);
const doctors = ref<SearchResponse['doctors']>([]);
const pages = ref<SearchResponse['pages']>([]);
const errorReq = ref();
const isLoading = ref(false);

const fetchResults = useDebounceFn(async query => {
  const { data, error, pending } = await useAsyncData('searchModal', () =>
    $fetch<SearchResponse>('/api/search', {
      method: 'GET',
      params: {
        query: query,
      },
    }),
  );
  if (data.value) {
    services.value = data.value?.services;
    doctors.value = data.value?.doctors;
    pages.value = data.value?.pages;
  }
  errorReq.value = error.value;
  isLoading.value = pending.value;
}, 500);

watch(
  () => searchQuery.value,
  newVal => {
    if (newVal && newVal.length > 2) {
      void fetchResults(newVal);
    }

    if (newVal.length < 1) {
      services.value = [];
      doctors.value = [];
      pages.value = [];
    }
  },
);

onMounted(() => {
  const input = document.querySelector<HTMLInputElement>('.modal-search .field__input');
  input?.focus();
});

const goSearchPage = () => {
  router.push({ path: '/search', query: { query: searchQuery.value } });
  onCloseModal();
};
const goToServices = () => {
  void router.push({ name: 'home', params: { chapters: 'services' } });
  onCloseModal();
};

router.beforeEach(() => {
  onCloseModal();
});
</script>

<template>
  <UIModal v-model="isOpen" styled-class="modal modal-search" @on-close-modal="onCloseModal">
    <div class="modal__input">
      <UIInput
        v-model="searchQuery"
        :autofocus="isOpen"
        placeholder="Поиск"
        type="search"
        @keydown.enter="goSearchPage"
      />
      <div class="modal__input-icons">
        <NuxtIcon
          v-if="searchQuery.length > 0"
          class="icon--close"
          name="IconCross"
          @click="searchQuery = ''"
        />
        <NuxtIcon v-if="isLoading" class="icon--close" name="IconLoading" />
        <NuxtIcon class="icon--search" name="IconSearch" @click="goSearchPage" />
      </div>
    </div>
    <template v-if="services.length > 0 || doctors.length > 0 || pages.length > 0">
      <h3 class="modal__header--title">Результат поиска</h3>
      <div class="modal__wrapper">
        <div class="modal__side modal__side--left">
          <template v-if="services.length > 0">
            <h4 class="modal__sub-title">Услуги</h4>
            <ul>
              <li v-for="service in services" :key="service.id">
                <NuxtLink
                  :to="{ name: 'home', params: { chapters: useSlugTransform(service.full_slug) } }"
                  class="modal__link"
                >
                  {{ service.title }}
                </NuxtLink>
              </li>
            </ul>
          </template>
          <template v-if="doctors.length > 0">
            <h4 class="modal__sub-title">Врачи</h4>
            <ul>
              <li v-for="doctor in doctors" :key="doctor.id">
                <NuxtLink
                  :to="{ name: 'home', params: { chapters: useSlugTransform(doctor.full_slug) } }"
                  class="modal__link"
                >
                  {{ doctor.title }}
                </NuxtLink>
              </li>
            </ul>
          </template>
        </div>
        <div v-if="pages.length > 0" class="modal__side modal__side--right">
          <h4 class="modal__sub-title">Статьи</h4>
          <ul>
            <li v-for="page in pages" :key="page.id">
              <NuxtLink
                :to="{ name: 'home', params: { chapters: useSlugTransform(page.slug) } }"
                class="modal__link"
              >
                {{ page.title }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <div
      v-if="
        searchQuery.length > 0 &&
        services.length === 0 &&
        doctors.length === 0 &&
        pages.length === 0
      "
      class="modal__no-results"
    >
      <span>По вашему запросу ничего не найдено.</span>
      <span>Пожалуйста, проверьте правильность написания.</span>
      <UIButton label="Перейти ко всем услугам" @click="goToServices" />
    </div>
  </UIModal>
</template>
