<template>
  <section class="intro-block">
    <div class="wrapper">
      <div v-if="image.source" class="intro-block__image">
        <UIPictureTag
          is-visible
          :image-webp="`${url}${image.webp}`"
          :image-original="`${url}${image.source}`"
          :image-seo="imageSeo"
        />
      </div>

      <div class="intro-block__content">
        <h2 v-if="title.length > 0" class="intro-block__header">{{ title }}</h2>
        <div v-if="text.length > 0" class="intro-block__text" v-html="text"></div>
        <div class="intro-block__buttons">
          <CButtonTrigger :button-title="buttonText" :button-trigger="buttonTrigger" />
          <UIButton
            v-if="buttonPhone.text && buttonPhone.phone"
            size="xl"
            class="pain"
            type="link"
            :link="`tel:${buttonPhone.phone}`"
            @click="goalsClickPhone"
            variant="white"
          >
            {{ buttonPhone.text }}
          </UIButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { BlockButtonTrigger, ImageItem, ImageSEO } from '~/types/pages';
import { useRuntimeConfig } from '#imports';
import './intro-block.scss';
import CButtonTrigger from '~/components/buttonTrigger/CButtonTrigger.vue';
import { yandexRichGoals } from '~/composables/yandexRichGoals';

interface Props {
  title: string;
  image: ImageItem;
  imageSeo: ImageSEO;
  text: string;
  buttonText: string;
  buttonTrigger: BlockButtonTrigger;
  buttonPhone: {
    text: string;
    phone: string;
  };
}

defineProps<Props>();

const { goalsClickPhone } = yandexRichGoals();

const url = useRuntimeConfig().public.siteUrl;
</script>

<style scoped></style>
