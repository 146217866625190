<template>
  <div class="pagination">
    <ul class="pagination__list">
      <li v-if="prevPageNumber > 1" class="pagination__item pagination__arrow">
        <NuxtLink
          class="pagination__link pagination__arrow--left"
          :to="{
            name: 'home',
            params: { chapters: slug },
            query: { page: prevPageNumber },
          }"
        >
          &lt;
        </NuxtLink>
      </li>
      <template v-for="(page, index) in pagination.totalPages">
        <li
          v-if="
            page === 1 ||
            page === pagination.totalPages ||
            page === pagination.page ||
            Math.abs(pagination.page - page) === 1
          "
          :key="`pagination_item_${index}`"
          class="pagination__item"
          :class="{ active: currentPage === page }"
        >
          <button class="pagination__link" @click="goPage(page)">
            {{ page }}
          </button>
        </li>

        <li
          v-else-if="Math.abs(pagination.page - page) === 2"
          :key="`pagination_item_plug_${index}`"
          class="pagination__item"
        >
          ...
        </li>
      </template>

      <li v-if="nextPageNumber < pagination.totalPages" class="pagination__item pagination__arrow">
        <NuxtLink
          class="pagination__link pagination__arrow--right"
          :to="{
            name: 'home',
            params: { chapters: slug },
            query: { page: nextPageNumber },
          }"
        >
          >
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, useRoute, useRouter } from '#imports';
import './styles.scss';

defineProps({
  pagination: {
    type: Object,
  },
});

const router = useRouter();

const route = useRouter().currentRoute.value;

const slug = computed(() => route.params.chapters);

async function goPage(page: number) {
  await router.push({
    name: 'home',
    replace: true,
    params: {
      chapters: slug.value,
    },
    query: {
      ...route.query,
      page: page,
    },
  });
}

const currentPage = computed(() => Number(route.query.page) || 1);

const nextPageNumber = computed(() => currentPage.value + 1);
const prevPageNumber = computed(() => currentPage.value - 1);
</script>

<style scoped></style>
