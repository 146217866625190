<template>
  <UIModal
    v-model="modalModel"
    styled-class="modal-registration"
    @onCloseModal="$emit('onCloseModal', false)"
  >
    <tempalte v-if="isSuccess">
      <h2 class="text-subheader">Спасибо за запись!</h2>
      <h3>В рабочее время клиники с вами свяжется менеджер для подтверждения записи</h3>
    </tempalte>
    <template v-else>
      <template v-if="isFinalStep">
        <div class="modal-registration__header">
          <UIButton variant="secondary" size="s" @click="onShowSecondStep">
            <NuxtIcon name="IconArrowRight"
          /></UIButton>
          <h2 class="text-subheader">Запись на прием к врачу</h2>
        </div>
      </template>
      <template v-else>
        <h2 class="text-subheader">Запись на прием к врачу</h2>
        <h3>
          Выберите специалиста или услугу и запишитесь в нашу стоматологическую клинику на прием.
        </h3>
      </template>

      <div v-if="isFirstStep" class="modal-registration__buttons">
        <UIButton label="Выбрать врача" @click="onShowSecondStep(false)" />
        <UIButton label="Выбрать услугу" @click="onShowSecondStep(true)" />
      </div>
      <template v-if="isSecondStep">
        <div
          class="modal-registration__body"
          :class="{ 'modal-registration__right--loading': schedulesPending && pending }"
        >
          <template v-if="schedulesPending && pending">
            <NuxtIcon name="IconLoading"></NuxtIcon>
          </template>
          <template v-else>
            <div class="modal-registration__left">
              <UISelect v-model="officesModel" placeholder="Филиал" :options="affiliatesOptions" />
              <UISelect
                v-model="servicesModel"
                :placeholder="isServices ? 'Услуги' : 'Специальность'"
                :options="isServices ? servicesOptions : professionsOptions"
              />
              <Swiper
                v-if="data && data.results.length"
                v-bind="swiperOptions"
                class="modal-registration__slider"
                @active-index-change="getCurrentDoctor"
                @init="getCurrentDoctor"
              >
                <SwiperSlide class="modal-registration__slide" v-for="doctor in data.results">
                  <div class="modal-registration__slide-wrapper">
                    <UIPictureTag
                      is-visible
                      :image-original="`${siteUrl}${doctor.image.source}`"
                      :image-seo="doctor.image_seo"
                      :image-webp="`${siteUrl}${doctor.image.webp}`"
                    />
                    <span>{{ doctor.title }}</span>
                    <span class="experience">Стаж {{ doctor.experience }}</span>
                    <span class="desc">{{ doctor.description }}</span>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div class="modal-registration__right">
              <ClientOnly>
                <VDatePicker
                  v-model.string="datePickerModel"
                  class="modal-registration__calendar"
                  :min-date="new Date(Date.now())"
                  :disabled-dates="[
                    {
                      start: null,
                      end: null,
                      repeat: {
                        on: days => availableDates.includes(days.id),
                      },
                    },
                  ]"
                  :attributes="[
                    {
                      content: {
                        style: {
                          fontWeight: 'bold',
                        },
                      },
                      dates: availableDates,
                    },
                  ]"
                  :masks="{ weekdays: 'WW', modelValue: 'YYYY-MM-DD' }"
                />
              </ClientOnly>
              <div class="modal-registration__hours">
                <template v-if="availableTimes.length">
                  <div
                    class="modal-registration__hour"
                    v-for="i in availableTimes"
                    :key="i"
                    :class="{ active: selectedTime === i.start }"
                    @click="onSelectTime({ start: i.start, end: i.end })"
                  >
                    {{ dateToTime(i.start) }}
                  </div>
                </template>
                <div v-else>нет записи</div>
              </div>
            </div>
          </template>
        </div>
        <div class="modal-registration__footer">
          <UIButton
            v-if="!schedulesPending || !pending"
            :disabled="!selectedTime"
            label="Далее"
            @click="onShowThreeStep"
          />
        </div>
      </template>
      <template v-if="isFinalStep">
        <div class="modal-registration__body" style="flex-direction: column">
          <div class="modal-registration__info">
            <div class="modal-registration__info-top">
              <div class="modal-registration__info-cell">
                <span class="modal-registration__info-title">Время</span>
                <span>{{ dateToTime(selectedTime) }}</span>
              </div>
              <div class="modal-registration__info-cell">
                <span class="modal-registration__info-title">Дата</span>
                <span>
                  {{ currentDate }}
                </span>
              </div>
              <div class="modal-registration__info-cell">
                <span class="modal-registration__info-title">Филиал</span>
                <span>{{ officesModel || 'Не выбран' }}</span>
              </div>
            </div>
            <div class="modal-registration__info-cell">
              <span class="modal-registration__info-title">Специалист</span>
              <span>{{ currentDoctor.title }}</span>
              <span>{{ currentDoctor.description }}</span>
            </div>
          </div>
          <form class="modal-registration__inputs" novalidate @change="execute">
            <div class="modal-registration__input">
              <UIInput
                v-model="formModel.full_name"
                class="modal-registration__input-name"
                type="text"
                placeholder="Введите имя"
              />
              <ul v-if="errorFields['full_name']" class="form-block__field-errors">
                <li v-for="error in errorFields['full_name']">
                  {{ error.message }}
                </li>
              </ul>
            </div>

            <div class="modal-registration__input">
              <UIInput
                class="modal-registration__input-phone"
                v-model="formModel.mobile_phone"
                type="tel"
                placeholder="Введите номер телефона"
              />
              <ul v-if="errorFields['mobile_phone']" class="form-block__field-errors">
                <li v-for="error in errorFields['mobile_phone']">
                  {{ error.message }}
                </li>
              </ul>
            </div>

            <div class="modal-registration__input modal-registration__input--comment">
              <UIInput
                v-model="formModel.comment"
                class="modal-registration__input-comment"
                type="textarea"
                placeholder="Введите комментарий"
              />
              <ul class="form-block__field-errors" v-if="errorFields['comment']">
                <li v-for="error in errorFields['comment']">
                  {{ error.message }}
                </li>
              </ul>
            </div>
            <UIButton label="Записаться" type="button" @click.stop="onSubmitForm" />
            <div class="modal-registration__checkbox">
              <ul v-show="errorCheckbox.length" class="form-block__field-errors">
                <li class="form-block__field-error">
                  {{ errorCheckbox }}
                </li>
              </ul>
              <UICheckbox class="form-block__policy" v-model="checkboxModel">
                Принимаю условия
                <NuxtLink
                  :to="{
                    name: 'home',
                    params: { chapters: useSlugTransform(config.privacyPolicy) },
                  }"
                  >политики конфиденциальности
                </NuxtLink>
                и даю разрешение на обработку персональных данных в соответствии с
                <NuxtLink
                  :to="{
                    name: 'home',
                    params: { chapters: useSlugTransform(config.consentPersonalData) },
                  }"
                  >согласием на обработку персональных данных
                </NuxtLink>
                .
              </UICheckbox>
            </div>
          </form>
        </div>
      </template>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import {
  computed,
  reactive,
  ref,
  useLazyAsyncData,
  useLazyFetch,
  useRuntimeConfig,
  useSlugTransform,
  useState,
  watch,
} from '#imports';
import UISelect from '~/components/UI/select/UISelect.vue';
import './styles.scss';
import '@vuepic/vue-datepicker/dist/main.css';
import type { ErrorResponse } from '~/server/utils/serverFetch';
import {
  type ApiDoctorsSchedulesItem,
  type ApiDoctorsSchedulesResponse,
  type ApiPageResponse,
  type BlockOffice,
  type DoctorsAppointmentCreateBody,
} from '~/types/pages';
import 'swiper/css';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useDateFormat } from '@vueuse/core';
import UIInput from '~/components/UI/input/UIInput.vue';
import UICheckbox from '~/components/UI/checkbox/UICheckbox.vue';
import type { SiteSettings } from '~/types/config';
import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator';
import type { Rules } from 'async-validator';
import UIButton from '~/components/UI/button/UIButton.vue';
import useGoogleRecaptcha from '~/composables/useGoogleRecaptcha';

interface Props {
  isOpen: boolean;
}

const props = defineProps<Props>();

const emmit = defineEmits(['onCloseModal']);

const { siteUrl } = useRuntimeConfig().public;

const modalModel = ref(props.isOpen);
const isFirstStep = ref(true);
const isSecondStep = ref(false);
const isFinalStep = ref(false);
const isServices = ref(false);
const officesModel = ref();
const servicesModel = ref();
const datePickerModel = ref(null);
const { executeRecaptcha } = useGoogleRecaptcha();

const onShowThreeStep = () => {
  isFirstStep.value = false;
  isSecondStep.value = false;
  isFinalStep.value = true;
};

const onShowSecondStep = (showServices = false) => {
  isFirstStep.value = false;
  isSecondStep.value = true;
  isFinalStep.value = false;

  isServices.value = showServices;
};

const professions = useState('professions');
const affiliates = useState<BlockOffice['offices']>('affiliates');
const config = useState<SiteSettings>('config');

const affiliatesOptions = ref([]);
const professionsOptions = ref([]);
const servicesOptions = ref([]);
const currentDoctor = ref();

const formModel = reactive<DoctorsAppointmentCreateBody>({
  doctor_id: 0,
  office_id: 0,
  dt_start: '0',
  dt_end: '0',
  full_name: '',
  comment: '',
  mobile_phone: '',
});

if (affiliates.value) {
  affiliatesOptions.value = affiliates.value.map(el => ({
    value: el.title,
    title: el.title,
  }));
}

const checkboxModel = ref(false);

if (professions.value) {
  professions.value.forEach(el => {
    professionsOptions.value.push({
      value: el.id,
      title: el.title,
    });

    servicesOptions.value.push({
      value: el.id,
      title: el.preview_text,
    });
  });
}

const swiperOptions = {
  slidesPerView: 1,
  spaceBetween: 16,
  createElements: true,
  autoHeight: false,
  centeredSlides: true,
  modules: [Pagination, Navigation],
  pagination: true,
  navigation: true,
  breakpoints: {
    769: {
      slidesPerView: 3,
    },
    521: {
      slidesPerView: 2,
    },
  },
};

const { data, error, pending } = await useLazyAsyncData<ErrorResponse | ApiPageResponse>(
  'getDoctorsListResponse',
  () => {
    return $fetch(`/api/doctors/list`, {
      method: 'GET',
      params: {
        profession_id: servicesModel.value,
        affiliate: officesModel.value,
      },
    });
  },
  {
    watch: [servicesModel, officesModel],
  },
);

const {
  data: schedules,
  pending: schedulesPending,
  error: schedulesError,
} = await useLazyAsyncData<ErrorResponse | ApiDoctorsSchedulesResponse>(
  'getDoctorsSchedules',
  () => {
    return $fetch(`/api/doctors/schedules`, {
      method: 'GET',
    });
  },
);

const getCurrentDoctor = swiperInstance => {
  const indexSlide = swiperInstance.activeIndex;

  currentDoctor.value = data.value.results[indexSlide];
};

const errorCheckbox = ref('');

const currentOfficeId = computed(() => {
  const office = affiliates.value.find(el => el.title === officesModel.value);

  return office?.id;
});

const currentSchedulesForDoctor = ref<ApiDoctorsSchedulesItem[]>([]);
const availableDates = ref<string[]>([]);
const availableTimes = ref([]);

const selectedTime = ref('');

const onSelectTime = (item: { start: string; end: string }) => {
  selectedTime.value = item.start;
  formModel.dt_end = item.end;
  formModel.dt_start = item.start;
};

watch(
  () => [currentDoctor.value, currentOfficeId.value],
  ([newValDoctor, newValOffice]) => {
    if (newValOffice) {
      currentSchedulesForDoctor.value = (schedules.value as ApiDoctorsSchedulesResponse).filter(
        el => el.doctor_id === newValDoctor.id && el.office_id === newValOffice,
      );
    } else {
      currentSchedulesForDoctor.value = (schedules.value as ApiDoctorsSchedulesResponse).filter(
        el => el.doctor_id === newValDoctor.id,
      );
    }

    datePickerModel.value = currentSchedulesForDoctor.value[0]?.cells[0].date
      ? useDateFormat(currentSchedulesForDoctor.value[0]?.cells[0].date, 'YYYY-MM-DD').value
      : null;

    availableDates.value = currentSchedulesForDoctor.value[0]?.cells.map(el => el.date) || [];

    if (currentSchedulesForDoctor.value.length > 0) {
      formModel.doctor_id = currentSchedulesForDoctor.value[0].doctor_id;
      formModel.office_id = currentSchedulesForDoctor.value[0].office_id;
    }
  },
  {
    immediate: false,
    deep: true,
  },
);

watch(
  () => [datePickerModel.value, currentSchedulesForDoctor.value],
  ([newDate, newSchedulesForDoctor]) => {
    if (newSchedulesForDoctor[0]) {
      availableTimes.value =
        newSchedulesForDoctor[0].cells.find(el => el.date === newDate)?.times || [];
      if (availableTimes.value.length) {
        onSelectTime({ start: availableTimes.value[0].start, end: availableTimes.value[0].end });
      }
    } else {
      availableTimes.value = [];
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const dateToTime = (dateString: string) => {
  const date = new Date(dateString);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}`;
};

const rules = ref<Rules>({
  full_name: {
    required: true,
    type: 'string',
  },
  comment: {
    required: false,
    type: 'string',
  },
  mobile_phone: {
    required: true,
    type: 'string',
  },
});

const { errorFields, execute } = useAsyncValidator(formModel, rules, {
  validateOption: {
    messages: {
      required: () => 'Обязательное поле',
    },
  },
  immediate: false,
  manual: true,
});

const serverErrors = ref<Record<string, string[]> | null>(null);
const isSuccess = ref(false);

const onSubmitForm = async (event: Event) => {
  const { pass } = await execute();

  event.preventDefault();

  serverErrors.value = null;

  if (!checkboxModel.value) {
    errorCheckbox.value = 'Cогласие не принято';
    return;
  }

  if (pass) {
    const formData = new FormData();

    const { token } = await executeRecaptcha('onlineRegistration');

    for (const item in formModel) {
      if (item === 'comment' && !formModel[item]) {
        formData.append(item, formModel['full_name']);
      } else {
        formData.append(item, formModel[item]);
      }
    }
    formData.append('g-recaptcha-response', token);

    const { data, error, status } = await useLazyFetch<any>('/api/doctors/appointmentCreate', {
      method: 'POST',
      body: formData,
    });

    if (status.value === 'success') {
      isSuccess.value = true;

      if (data.value.status === 400) {
        serverErrors.value = data.value.data;
      }

      window.setTimeout(() => {
        emmit('onCloseModal', false);
      }, 5000);
    }
    if (error.value) {
      serverErrors.value = error.value.data.data;
    }
  }
};

const currentDate = computed(
  () => useDateFormat(new Date(datePickerModel.value), 'DD.MM.YYYY', { locales: 'ru-ru' }).value,
);
</script>
