<template>
  <main>
    <Error v-if="errorNotFound" :error="errorNotFound" />
    <SearchPage v-else-if="slug === 'search'" />
    <template v-else>
      <CArticlePage
        v-if="
          pageTemplate === 'page-detail' ||
          pageTemplate === 'services-detail' ||
          pageTemplate === 'services-category'
        "
        :template="pageTemplate"
        @error-not-found="errorArticlePage"
      />
      <CDoctorsList v-if="pageTemplate === 'doctors-category'" />
      <CDoctorDetailPage
        v-if="pageTemplate === 'doctors-detail'"
        @error-not-found="errorArticlePage"
      />
      <CServicesList v-if="pageTemplate === 'services-list'" />
      <CIndexPage v-if="pageTemplate === 'unique-placeholder'" />
      <CSectionPage
        v-if="pageTemplate === 'page-list' || pageTemplate === 'page-list-sidebar'"
        :is-show-filters="data.is_filters_displayed"
        :id="data.id"
      />
    </template>
  </main>
</template>

<script setup lang="ts">
import { createError, computed, ref, useRouter, useAsyncData } from '#imports';
import CSectionPage from '@/components/pages/CSectionPage.vue';
import CIndexPage from '@/components/pages/indexPage/CIndexPage.vue';
import CArticlePage from '@/components/pages/articlePage/CArticlePage.vue';
import CServicesList from '@/components/pages/servicesList/CServicesList.vue';
import Error from '~/error.vue';
import CDoctorsList from '@/components/pages/doctorsList/CDoctorsList.vue';
import CDoctorDetailPage from '@/components/pages/doctorDetail/CDoctorDetailPage.vue';
import { type PageResult, type PageTemplate } from '~/types/pages';
import type { ErrorResponse } from '~/server/utils/serverFetch';
import { isErrorResponse } from '~/server/utils/serverError';
import SearchPage from '~/components/pages/SearchPage.vue';

const route = useRouter().currentRoute.value;

const slug = computed(() => {
  if (route.path === '/') {
    return 'index';
  }

  if (route.params && route.params.chapters) {
    return (route.params.chapters as string[]).join('/');
  }
});

const pageTemplate = ref<PageTemplate>();
const errorNotFound = ref<ErrorResponse>();

const { data, error } = await useAsyncData<ErrorResponse | PageResult>(
  'getTemplateResponse',
  () => {
    return $fetch(`/api/pages/slug`, {
      method: 'GET',
      params: {
        slug: slug.value,
      },
    });
  },
  {
    pick: ['template', 'status', 'data', 'id', 'is_filters_displayed'],
    watch: [slug],
  },
);

if (data.value) {
  if (!isErrorResponse(data.value)) {
    pageTemplate.value = data.value.template;
  } else {
    errorNotFound.value = {
      statusCode: data.value.status,
      message: data.value.data?.detail,
    };
  }
}

const errorArticlePage = () => {
  pageTemplate.value = '404-error';

  errorNotFound.value = {
    statusCode: 404,
    message: 'Страница не найдена.',
  };
};

if (error.value) {
  throw createError(error.value);
}
</script>
