const SERVICES_ICONS: Record<string, string> = {
  Hygiene_prevention: 'IconServicesHygienePrevention',
  Bite_correction: 'IconServicesBiteCorrection',
  Periodontics: 'IconServicesPeriodontic',
  Implantation_surgery: 'IconServicesImplantationSurger',
  Dental_prosthetics: 'IconServicesDentalProsthetics',
  Therapeutic_dentistry: 'IconServicesTherapeuticDentistry',
  Aesthetic_dentistry: 'IconServicesAestheticDentistr',
  sleep: 'IconServicesVoSne',
  'first-visit': 'IconServicesFirstVisit',
};

export const useServicesIconName = (key: string): string => {
  if (SERVICES_ICONS[key]) {
    return SERVICES_ICONS[key];
  }

  return 'IconServicesDefault';
};
