import { default as EntryPageHE0Ask03jHMeta } from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/pages/EntryPage.vue?macro=true";
export default [
  {
    name: EntryPageHE0Ask03jHMeta?.name ?? "EntryPage",
    path: EntryPageHE0Ask03jHMeta?.path ?? "/EntryPage",
    meta: EntryPageHE0Ask03jHMeta || {},
    alias: EntryPageHE0Ask03jHMeta?.alias || [],
    redirect: EntryPageHE0Ask03jHMeta?.redirect,
    component: () => import("/var/www/ao-stom/data/www/ao-stom.ru-nuxt/pages/EntryPage.vue").then(m => m.default || m)
  }
]