<template>
  <section class="map" id="officeTabs">
    <h2 v-if="title" class="map__title wrapper">{{ title }}</h2>

    <div class="map__tabs">
      <div class="wrapper">
        <div class="map__tabs-header">
          <button
            v-for="(item, index) in offices"
            :key="`${index}_${item.title}`"
            class="map__tabs-btn gap-4"
            :class="{ active: activeTab === index }"
            @click="openTab(index)"
          >
            <NuxtIcon class="icon" name="IconMetro" />
            {{ item.title }}
          </button>
        </div>
      </div>
      <div class="map__tabs-wrapper">
        <div class="wrapper">
          <div class="map__tabs-wrapper-wrap">
            <div class="map__tabs-content">
              <UIPictureTag
                v-if="offices[activeTab].image"
                is-visible
                :image-original="`${url}${offices[activeTab].image.source}`"
                :image-seo="{
                  image_title: offices[activeTab].title,
                  image_alt: offices[activeTab].title,
                }"
                :image-webp="`${url}${offices[activeTab].image.webp}`"
              />
              <a
                :href="`tel:${offices[activeTab].phone}`"
                @click="goalsClickPhone"
                class="map__tabs-phone"
                >{{ offices[activeTab].phone }}</a
              >
              <div class="map__tabs-item">
                <NuxtIcon name="IconMetro" />
                {{ offices[activeTab].title }}
              </div>
              <div class="map__tabs-item">
                <NuxtIcon name="IconClock" filled />
                {{ offices?.[activeTab].workTime }}
              </div>
              <div class="map__tabs-item">
                <NuxtIcon name="IconMapPin" filled />
                {{ offices?.[activeTab].address }}
              </div>

              <UIButton
                type="link"
                link-type="_blank"
                variant="primary"
                :link="`https://yandex.ru/maps/?ll=${offices?.[activeTab].lon}%2C${offices?.[activeTab].lat}&mode=routes&rtext=~${offices?.[activeTab].lat}%2C${offices?.[activeTab].lon}&rtt=pd&z=13 size=l`"
              >
                <NuxtIcon name="IconRoute" />
                Проложить маршрут
              </UIButton>

              <CRatingBlock
                v-if="offices?.[activeTab].rating"
                :media="offices?.[activeTab].rating.media"
                :custom-breakpoints="{
                  520: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                    centeredSlides: false,
                  },
                  320: {
                    slidesPerView: 2.5,
                    spaceBetween: 12,
                    centeredSlides: false,
                  },
                }"
                :slides-per-view="3.5"
              />
            </div>
            <div class="map__tabs-map">
              <client-only>
                <YandexMap
                  v-if="centerCoords"
                  v-model="map"
                  :settings="{
                    location: {
                      bounds: locationBounds,
                    },
                    margin: [115, 0, 0, 0],
                  }"
                  width="100%"
                  height="100%"
                >
                  <YandexMapDefaultSchemeLayer />
                  <YandexMapDefaultFeaturesLayer />
                  <YandexMapControls :settings="{ position: 'right' }">
                    <YandexMapZoomControl />
                  </YandexMapControls>

                  <YandexMapMarker
                    v-for="(pin, pIndex) in offices"
                    :key="pIndex"
                    :settings="{ coordinates: [pin.lon as number, pin.lat as number] }"
                  >
                    <img
                      :src="pIndex === activeTab ? mapPinActive : mapPin"
                      :style="{
                        minWidth: pIndex === activeTab ? '74px' : '50px',
                        position: 'relative',
                        boxSizing: 'border-box',
                        transform: 'translate(-50%, calc(-50% - 24px))',
                        cursor: 'pointer',
                        transition: '.2s linear',
                      }"
                      :alt="pin.title"
                      @click="openTab(pIndex)"
                    />
                  </YandexMapMarker>
                </YandexMap>
              </client-only>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import './styles.scss';
import {
  YandexMap,
  YandexMapDefaultSchemeLayer,
  YandexMapControls,
  YandexMapMarker,
  YandexMapZoomControl,
  YandexMapDefaultFeaturesLayer,
} from 'vue-yandex-maps';
import { computed, ref, shallowRef, useRuntimeConfig } from '#imports';
import mapPin from '@/assets/images/map-pin.png';
import mapPinActive from '@/assets/images/map-pin-active.png';
import type { YMap } from '@yandex/ymaps3-types';
import CRatingBlock from '~/components/blocks/ratingBlock/CRatingBlock.vue';
import type { BlockRating, ImageItem } from '~/types/pages';
import { yandexRichGoals } from '~/composables/yandexRichGoals';

interface Props {
  title?: string;
  offices: {
    title: string;
    workTime: string;
    address: string;
    phone: string;
    lat: number;
    lon: number;
    image: ImageItem;
    rating: BlockRating;
  }[];
}

const props = defineProps<Props>();
const map = shallowRef<YMap | null>(null);
const url = useRuntimeConfig().public.siteUrl;
const { goalsClickPhone } = yandexRichGoals();

const centerCoords = computed(() => {
  if (!Array.isArray(props.offices) || props.offices?.length === 0) {
    return null; // Обработка случая, когда массив пуст или не является массивом
  }

  // Используем reduce для суммирования всех значений lat и lon,
  // пропуская элементы с null значениями в lat или lon
  const filteredOffices = props.offices.filter(item => item.lat !== null && item.lon !== null);

  if (filteredOffices.length === 0) {
    return null; // Если все элементы содержат null в lat или lon, возвращаем null
  }

  const sum = filteredOffices.reduce(
    (acc, coord) => {
      return {
        lat: Number(acc.lat) + Number(coord.lat),
        lon: Number(acc.lon) + Number(coord.lon),
      };
    },
    { lat: 0, lon: 0 },
  );

  // Рассчитываем среднее арифметическое для lat и lon
  return {
    lat: sum.lat / filteredOffices.length,
    lon: sum.lon / filteredOffices.length,
  };
});

const activeTab = ref(0);

const openTab = (index: number) => {
  activeTab.value = index;

  if (window && window.innerWidth <= 1024) {
    document.getElementById('officeTabs').scrollIntoView({
      behavior: 'smooth',
    });
  }
};

const locationBounds = computed(() =>
  props.offices.map(el => [Number(el.lon.toFixed(1)), Number(el.lat.toFixed(1))]),
);
</script>
