<template>
  <UIButton
    v-if="buttonTrigger.type === 'form' || buttonTrigger.type === 'doctor_execute'"
    :variant="buttonVariant || 'primary'"
    :size="buttonSize"
    @click="openFormModal"
    >{{ buttonTitle }}</UIButton
  >
  <UIButton
    v-else
    :link="link"
    type="link"
    :size="buttonSize"
    :link-type="buttonTrigger.type === 'link' ? '_self' : '_blank'"
    :variant="buttonVariant"
    >{{ buttonTitle }}</UIButton
  >

  <COnlineRegistration
    v-if="buttonTrigger.type === 'doctor_execute' && isModalOpen"
    :is-open="isModalOpen"
    @onCloseModal="isModalOpen = false"
  />
  <UIModal v-if="buttonTrigger.type === 'form'" v-model="isModalOpen" styled-class="modal-form">
    <CDynamicForm
      v-if="currentForm"
      :title="currentForm.form_title || currentForm?.title"
      :help-text="currentForm?.help_text"
      :form-key="currentForm?.key"
      :fields="currentForm?.fields"
      is-modal
      @open-success-modal="closeModal"
    />

    <div class="modal-form__affiliates">
      <div v-for="affiliate in affiliates" :key="affiliate.name" class="modal-form__affiliate">
        <div class="title">
          <NuxtIcon name="IconMetro" filled />
          {{ affiliate.title }}
        </div>
        <div class="address">
          <NuxtIcon name="IconMapPin" filled />
          {{ affiliate.address }}
        </div>
        <UIButton
          class="modal-form__affiliate-button"
          variant="secondary"
          size="xl"
          link-type="_self"
          type="link"
          :link="`tel:${affiliate.phone}`"
          @click="goalsClickPhone"
        >
          {{ affiliate.phone }}
        </UIButton>
      </div>
    </div>

    <ul v-if="config.socials" class="modal-form__socials">
      <template v-for="item in config.socials">
        <li v-if="item.link.length > 0" :key="item.name" class="modal-form__socials-item">
          <NuxtLink :to="item.link" target="_blank" class="modal-form__socials-link">
            <NuxtIcon v-if="item.name" :name="`IconSocial${item.name}`" filled />
          </NuxtLink>
        </li>
      </template>
    </ul>
  </UIModal>
</template>

<script setup lang="ts">
import type { BlockButtonTrigger, FormModel } from '~/types/pages';
import { computed, ref, useSlugTransform, useState } from '#imports';
import UIModal from '~/components/UI/modal/UIModal.vue';
import CDynamicForm from '~/components/blocks/dynamicForm/CDynamicForm.vue';
import UIButton from '~/components/UI/button/UIButton.vue';
import COnlineRegistration from '~/components/blocks/onlineRegistration/COnlineRegistration.vue';
import { yandexRichGoals } from '~/composables/yandexRichGoals';

interface Props {
  buttonTitle: string;
  buttonTrigger: BlockButtonTrigger;
  buttonVariant?: 'secondary' | 'primary' | 'white';
  buttonSize?: 'xl' | 'l' | 's';
}

const props = defineProps<Props>();

const link = computed(() => {
  if (props.buttonTrigger.type === 'link') {
    return { name: 'home', params: { chapters: useSlugTransform(props.buttonTrigger.content) } };
  }

  return props.buttonTrigger.content;
});

const forms = useState<FormModel[]>('forms');

const isModalOpen = ref(false);

const currentForm = computed(() =>
  forms.value.find((form: FormModel) => form.key === props.buttonTrigger.content),
);

const config = useState('config');
const affiliates = useState('affiliates');
const openFormModal = () => {
  isModalOpen.value = true;
};

const { goalsClickPhone } = yandexRichGoals();

const closeModal = () => {
  window.setTimeout(() => {
    isModalOpen.value = false;
  }, 5000);
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
