<template>
  <picture ref="target">
    <source :srcset="imageSrc()?.imageWebp" type="image/webp" />
    <img
      :src="imageSrc()?.imageOriginal"
      :alt="imageSeo?.image_alt"
      :title="imageSeo?.image_title"
    />
  </picture>
</template>

<script setup lang="ts">
import type { ImageSEO } from '~/types/pages';
import { computed, ref } from '#imports';
import { useElementVisibility } from '@vueuse/core';
import placeholderImageWebp from '@/assets/images/placeholder-image.webp';
import placeholderImage from '@/assets/images/placeholder-image.png';

interface Props {
  imageWebp: string | null;
  imageOriginal: string | null;
  imageSeo?: ImageSEO;
  isVisible?: boolean;
}

interface ImageSrc {
  imageWebp: string;
  imageOriginal: string;
}

const imagePlaceholder = {
  imageWebp: placeholderImageWebp,
  imageOriginal: placeholderImage,
};

const props = defineProps<Props>();

const target = ref(null);

const isVisibility = computed(() => props.isVisible);
const isFirstVisible = ref(false);

const targetIsVisible = useElementVisibility(target);

const imageSrc = (): ImageSrc => {
  let resultImageSrc: ImageSrc;

  if (
    !props.imageWebp ||
    !props.imageOriginal ||
    props.imageWebp.endsWith('null') ||
    props.imageOriginal.endsWith('null')
  ) {
    resultImageSrc = imagePlaceholder;
    return resultImageSrc;
  }

  if (isVisibility.value || isFirstVisible.value || targetIsVisible.value) {
    resultImageSrc = {
      imageWebp: props.imageWebp,
      imageOriginal: props.imageOriginal,
    };

    if (targetIsVisible.value) {
      isFirstVisible.value = true;
    }

    return resultImageSrc;
  }

  return imagePlaceholder;
};
</script>
