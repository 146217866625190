<template>
  <main class="search-page">
    <div class="wrapper">
      <h1>Поиск</h1>

      <div class="search-page__input">
        <UIInput
          v-model="searchModel"
          placeholder="Поиск"
          :type="'search'"
          @update:modelValue="onSendSearch()"
          @keydown.enter="onSendSearch()"
        />
        <div class="search-page__input-icons">
          <NuxtIcon
            v-if="searchModel.length > 0"
            class="icon--close"
            name="IconCross"
            @click="clearSearch"
          />
          <NuxtIcon v-if="isLoading" class="icon--load" name="IconLoading" />
          <NuxtIcon class="icon--search" name="IconSearch" @click="onSendSearch()" />
        </div>
      </div>
      <div class="search-page__content">
        <h2 v-if="(!searchQuery || searchQuery.length < 1) && !isLoading">
          Для получения результатов введите поисковой запрос
        </h2>
        <NuxtIcon v-if="isLoading" class="icon--load" name="IconLoading" />
        <template v-if="!isLoading && isHasData">
          <div v-if="servicesAdult.length > 0" class="search-page__list">
            <h2>Услуги - Взрослые</h2>
            <CServricesCard
              v-for="service in servicesAdult"
              :key="service.id"
              class="search-page__card"
              :title="service.title"
              :icon="service.icon"
              :slug="service.full_slug"
            />
          </div>
          <div v-if="servicesChild.length > 0" class="search-page__list">
            <h2>Услуги - Дети</h2>
            <CServricesCard
              v-for="service in servicesChild"
              :key="service.id"
              class="search-page__card"
              :title="service.title"
              :icon="service.icon"
              :slug="service.full_slug"
            />
          </div>
          <div v-if="doctors.length > 0" class="search-page__list search-page__list--cards">
            <h2>Врачи</h2>
            <CSpesialistCard
              v-for="doctor in doctors"
              :id="doctor.id"
              :key="doctor.id"
              class="search-page__card"
              :image="doctor.image"
              :full_slug="doctor.full_slug"
              :full_name="doctor.title"
              :preview_text="formatCategories(doctor.category.title, doctor.extra_categories)"
              :image_seo="doctor.image_seo"
              :link="doctor.full_slug"
              :experience_from="doctor.experience"
            />
          </div>
          <div v-if="pages.length > 0" class="search-page__list search-page__list--cards">
            <h2>Статьи</h2>
            <CArticleCard
              v-for="article in pages"
              :key="article.id"
              class="search-page__card"
              :title="article.title"
              :date="article.publish_from"
              :image="article.image"
              :image-seo="article.image_seo"
              :text="article.preview_text"
              :slug="article.slug"
            />
          </div>
        </template>
        <template
          v-if="(!isLoading || !isHasData) && searchModel.length > 0"
          class="search-page__list"
        >
          <h2>По данному запросу нет результатов</h2>
          <h3>Попробуйте изменить запрос</h3>
        </template>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { useRouter, ref, onMounted } from '#imports';
import type { SearchResponse } from '~/types/search';
import CServricesCard from '~/components/sectionBlocks/servicesCard/CServricesCard.vue';
import CSpesialistCard from '~/components/blocks/specialistCard/CSpesialistCard.vue';
import CArticleCard from '~/components/sectionBlocks/articleCard/CArticleCard.vue';
import UIInput from '~/components/UI/input/UIInput.vue';
import type { ProfessionsItem } from '~/types/config';
import { useAsyncData } from '#app';
import { useDebounceFn } from '@vueuse/core';

const router = useRouter();
const route = router.currentRoute.value;

const searchQuery = ref();

const searchModel = ref<string>('');
const servicesChild = ref<SearchResponse['services']>([]);
const servicesAdult = ref<SearchResponse['services']>([]);
const doctors = ref<SearchResponse['doctors']>([]);
const pages = ref<SearchResponse['pages']>([]);

const isLoading = ref(false);
const isHasData = ref(false);

function formatCategories(categoryName: string, extraCategories: ProfessionsItem[]) {
  if (extraCategories && extraCategories.length > 0) {
    const titles = extraCategories.map(category => category.title);

    if (!categoryName) {
      return `${titles.join(',')}`;
    }

    if (titles.length) {
      return `${categoryName} - ${titles.join(', ')}`;
    }
  }

  return `${categoryName}`;
}

const clearSearch = () => {
  searchModel.value = '';
  searchQuery.value = '';
  router.replace({ query: { query: '' } });
};

const onSendSearch = useDebounceFn(async () => {
  isLoading.value = true;

  if (searchModel.value !== route.query.query) {
    void router.replace({ query: { query: searchModel.value } });
  }

  const { data, error, pending } = await useAsyncData<SearchResponse>('search', () =>
    $fetch('/api/search', {
      method: 'GET',
      params: {
        query: searchModel.value.trim(),
      },
    }),
  );

  if (data.value) {
    if (data.value.services?.length > 0) {
      data.value.services.forEach(el => {
        if (el.age === 'adult') {
          servicesAdult.value.push(el);
        }

        if (el.age === 'children') {
          servicesChild.value.push(el);
        }
      });
    }

    doctors.value = data.value?.doctors;
    pages.value = data.value?.pages;

    isHasData.value =
      servicesAdult.value.length > 0 ||
      servicesChild.value.length > 0 ||
      pages.value.length > 0 ||
      doctors.value.length > 0;
  }

  isLoading.value = pending.value;
}, 1500);

onMounted(async () => {
  if (route.query.hasOwnProperty('query')) {
    searchQuery.value = route.query.query;
    searchModel.value = route.query.query as string;
    await onSendSearch();
  }
});
</script>

<style lang="scss">
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.search-page {
  background-color: $color-bg-primary;
  padding: 48px 0 48px;

  h1 {
    text-align: center;
    @include header-h-1();
    margin-bottom: 24px;
  }

  h2 {
    text-align: center;
    @include header-h-2();
    margin-bottom: 24px;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon--load {
      font-size: 72px;
      animation: rotate 1s infinite;
    }
  }

  &__input {
    margin-bottom: 48px;
    max-width: 100%;
    position: relative;

    .field__input {
      width: 100%;
      box-sizing: border-box;
    }

    &-icons {
      position: absolute;
      font-size: 18px;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      gap: 8px;
      display: flex;
      align-self: center;
      color: $color-icon-secondary;
    }

    .icon--close {
      cursor: pointer;
    }

    .icon--load {
      animation: rotate 1s infinite;
    }

    .icon--search {
      color: $color-icon-accent;
      cursor: pointer;
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
    width: 100%;

    h2 {
      flex: 1 1 100%;
      text-align: left;
      margin-bottom: 16px;
      @include header-h-2();
    }

    &--cards {
      @media (max-width: $media-l) {
        .search-page__card {
          flex: 0 0 calc(50% - 16px);
          max-width: calc(50% - 16px);
        }
      }
    }
  }

  &__card {
    flex: 0 0 calc(25% - 16px);
    width: 100%;
    max-width: calc(25% - 16px);

    @media (max-width: $media-l) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
