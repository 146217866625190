<template>
  <div class="page">
    <CHeader v-bind="headerData" />
    <div class="page__content">
      <NuxtPage :route="$route" :page-key="$route.fullPath" />
    </div>
    <ul class="mob-menu">
      <li v-for="item in mobileMenu" :key="item.title" class="mob-menu__item">
        <NuxtLink
          :to="{ name: 'home', params: { chapters: useSlugTransform(item.slug) } }"
          class="mob-menu__link"
        >
          <UIPictureTag
            class="icon"
            :image-webp="`${url}${item.image.webp}`"
            :image-original="`${url}${item.image.source}`"
            :image-seo="item.image_seo"
          />
          {{ item.title }}
        </NuxtLink>
      </li>
      <li class="mob-menu__item">
        <div class="mob-menu__link" @click="$refs.buttonQuiz.click()">
          <div class="icon">
            <NuxtIcon name="IconPrice" filled />
          </div>
          Стоимость
        </div>
      </li>
    </ul>
    <CFooter v-bind="footerData" />
    <div v-show="isShowQuiz" class="quiz wrapper">
      <NuxtIcon class="quiz__close" name="IconCancelSmall" @click="onHideQuiz"></NuxtIcon>
      <div class="quiz__txt">
        Рассчитайте предварительную цену, пройдя короткий тест за 20 секунд
      </div>
      <button
        ref="buttonQuiz"
        class="button button--size-l button--primary"
        type="button"
        id="buttonQuiz"
      >
        Рассчитать
      </button>
    </div>
    <CCookies />
  </div>
</template>

<script setup lang="ts">
import {
  useAsyncData,
  createError,
  useHead,
  provide,
  ref,
  useState,
  useSlugTransform,
  useRuntimeConfig,
  useNuxtApp,
  onMounted,
  watchEffect,
} from '#imports';
import type { ClientInitResponse } from '@/types/client/init';
import CHeader from '@/components/header/CHeader.vue';
import CFooter from '@/components/footer/CFooter.vue';
import type { MenuItem } from '~/types/menu';
import UIPictureTag from '~/components/UI/picture/UIPictureTag.vue';
import CCookies from '~/components/cookies/CCookies.vue';
import { useSchemaOrg } from '@unhead/schema-org';

const { data } = await useAsyncData<ClientInitResponse>('init', () =>
  $fetch('/api/init', {
    method: 'GET',
  }).catch(e => {
    throw createError(e);
  }),
);

const headerData = ref<{
  menuItems: MenuItem[];
  headerEmail: string;
  headerPhone: string;
}>();

const footerData = ref<{
  menuItems: MenuItem[];
  footerEmail: string;
  footerPhone: string;
}>();

const mobileMenu = ref<MenuItem[]>();

const url = useRuntimeConfig().public.siteUrl;

if (data.value) {
  if (data.value.forms) {
    useState('forms', () => data.value?.forms);
  }

  if (data.value.affiliates) {
    useState('affiliates', () => data.value?.affiliates);
  }

  if (data.value.professions) {
    useState('professions', () => data.value?.professions);
  }

  if (data.value.servicesCatalog) {
    useState('servicesCatalog', () => data.value?.servicesCatalog);
  }

  if (data.value.config) {
    useState('config', () => data.value?.config);

    useHead({
      title: (data.value.config.siteName.value as string) || 'AO-СТОМ',
    });

    provide('pageCount', data.value.config.pageCount);
    provide('serviceCatalogAdult', data.value.config.serviceCatalogAdult);
    provide('serviceCatalogKids', data.value.config.serviceCatalogKids);
  }

  if (data.value.menu) {
    headerData.value = {
      menuItems: data.value.menu.header_menu,
      headerEmail: data.value.config?.headerEmail.value as string,
      headerPhone: data.value.config?.headerPhone.value as string,
    };

    footerData.value = {
      menuItems: data.value.menu.footer_menu,
      footerEmail: data.value.config?.footerEmail.value as string,
      footerPhone: data.value.config?.footerPhone.value as string,
    };

    mobileMenu.value = data.value.menu.footer_menu_mobile;
  }
}

const nuxtApp = useNuxtApp();

nuxtApp.hook('page:finish', () => {
  window.scrollTo(0, 0);
});

useSchemaOrg([
  {
    '@type': 'MedicalClinic',
    name: 'Стоматологический центр в Москве АО Стом',
    url: useRuntimeConfig().public.siteUrl,
    logo: '/android-chrome-192x192.png',
    image: '/android-chrome-192x192.png',
    description: data.value?.config?.siteDescription.value || '',
    address: [
      {
        '@type': 'PostalAddress',
        streetAddress: data.value?.affiliates?.[0].address,
        addressLocality: 'Москва',
        addressCountry: 'Россия',
      },
      {
        '@type': 'PostalAddress',
        streetAddress: data.value?.affiliates?.[1].address,
        addressLocality: 'Москва',
        addressCountry: 'Россия',
      },
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: data.value?.affiliates?.[0].phone,
      },
      {
        '@type': 'ContactPoint',
        telephone: data.value?.affiliates?.[1].phone,
      },
    ],
  },
]);
const isShowQuiz = ref(false);

onMounted(() => {
  if (process.browser) {
    isShowQuiz.value = !window.localStorage.getItem('hideQuiz');
  }
});

const onHideQuiz = () => {
  isShowQuiz.value = false;
  window.localStorage.setItem('hideQuiz', 'true');
  window.setTimeout(() => {
    isShowQuiz.value = true;
    window.localStorage.removeItem('hideQuiz');
  }, 18000);
};

watchEffect(() => {
  if (process.browser) {
    window.addEventListener('storage', () => {
      isShowQuiz.value = window.localStorage.getItem('hideQuiz') === 'true';
    });
  }
});
</script>
