import revive_payload_client_4sVQNw7RlN from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_j7wLaRg9eH from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import nuxt3_plugin_BdfRvWAAOY from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import chunk_reload_client_UciE0i6zes from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import google_recaptcha_rYK9Kziv68 from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/plugins/google-recaptcha.ts";
import sanitizeDOM_760AI9WlSn from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/plugins/sanitizeDOM.ts";
import sentry_3AyO8nEfhE from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_j7wLaRg9eH,
  nuxt3_plugin_BdfRvWAAOY,
  chunk_reload_client_UciE0i6zes,
  google_recaptcha_rYK9Kziv68,
  sanitizeDOM_760AI9WlSn,
  sentry_3AyO8nEfhE
]