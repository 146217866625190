import { DataSource, PhotoSwipeOptions } from 'photoswipe';
import { SlideData } from 'photoswipe/dist/types/slide/slide';
import { onMounted } from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore не подтягивается модуль в ide
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export const useMediaModal = (dataSource: SlideData[]) => {
  const options: PhotoSwipeOptions = {
    dataSource: dataSource,
    pswpModule: () => import('photoswipe'),
    bgOpacity: 0.8,
    loop: true,
    indexIndicatorSep: '/',
    showHideAnimationType: 'zoom',
    padding: { top: 50, bottom: 50, left: 100, right: 100 },
    preloaderDelay: 0.3,
    spacing: 0.5,
    closeOnVerticalDrag: false,
    showAnimationDuration: 0,
    hideAnimationDuration: 0,
  };

  const lightbox = new PhotoSwipeLightbox(options);

  onMounted(() => {
    lightbox.init();
  });

  const openMediaModal = (index: number) => {
    lightbox.loadAndOpen(index, options.dataSource as DataSource);
  };

  const destroyMediaModal = () => {
    lightbox.destroy();
  };

  return {
    openMediaModal,
    destroyMediaModal,
  };
};
