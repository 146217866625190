<template>
  <div class="text-block">
    <div :id="id" class="wrapper">
      <h2 v-if="title" class="text-subheader">{{ title }}</h2>
      <div v-if="text" v-html="text"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import './styles.scss';
import { onMounted } from '#imports';

interface Props {
  id?: number;
  text: string;
  title?: string;
}

defineProps<Props>();

const wrapperTables = () => {
  const tables: NodeListOf<HTMLTableElement> = document.querySelectorAll('table');

  tables.forEach(table => {
    if (!table.closest('.table-wrapper')) {
      const divWrapper = document.createElement('div');

      divWrapper.classList.add('table-wrapper');
      table.parentNode.replaceChild(divWrapper, table);
      divWrapper.appendChild(table);
    }
  });
};

onMounted(() => {
  wrapperTables();
});
</script>
