import { H3Error } from 'h3';
import { createError } from '#imports';
import type { ErrorResponse } from '~/server/utils/serverFetch';

export const handleError = (error: H3Error) => {
  if (
    error.statusCode?.toString().startsWith('4') ||
    error.statusCode?.toString().startsWith('5')
  ) {
    createError(error);
    throw new Error('Error');
  }
  throw error;
};

export function isErrorResponse(obj: any): obj is ErrorResponse {
  return 'status' in obj && typeof obj.status === 'number';
}
