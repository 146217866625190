<template>
  <footer class="footer">
    <div class="wrapper">
      <template v-if="formFooter">
        <!--        <UIButton v-if="isL" size="l" variant="white" label="Написать нам" />-->
        <CButtonTrigger
          v-if="isL"
          button-title="Написать нам"
          button-variant="white"
          :button-trigger="buttonForm"
        />
        <CSimpleFormBlock
          v-else
          :title="formFooter.form_title"
          :help-text="formFooter.help_text"
          :form-key="formFooter.key"
          :fields="formFooter.fields"
        />
      </template>
      <div class="footer__top">
        <ul class="footer__col">
          <li v-for="(item, index) in menuItems" :key="`footer_menu_${index}`" class="footer__item">
            <NuxtLink
              :to="
                item.type === 'direct_link'
                  ? item.url
                  : { name: 'home', params: { chapters: item.slug.split('/') } }
              "
              class="footer__link"
              :target="item.target"
            >
              {{ item.title }}
            </NuxtLink>
          </li>
        </ul>
        <template v-if="affiliates.length > 0">
          <ul v-for="affiliate in affiliates" :key="affiliate.id" class="footer__col">
            <li v-if="affiliate.phone" class="footer__item">
              <a class="footer__link" :href="`tel:${affiliate.phone}`" @click="goalsClickPhone">{{
                affiliates.phone
              }}</a>
            </li>
            <li v-if="affiliate.title" class="footer__item">
              <span class="footer__link">
                <NuxtIcon name="IconMetro" />
                {{ affiliate.title }}
              </span>
            </li>
            <li v-if="affiliate.working_time" class="footer__item">
              <span class="footer__link">
                <NuxtIcon name="IconClock" filled />
                {{ affiliate.working_time }}
              </span>
            </li>
            <li v-if="affiliate.address" class="footer__item">
              <span class="footer__link">
                <NuxtIcon name="IconMapPin" filled />
                {{ affiliate.address }}
              </span>
            </li>
          </ul>
        </template>
      </div>
      <ul v-if="config.socials" class="footer__socials">
        <template v-for="item in config.socials">
          <li v-if="item.link.length > 0" :key="item.name" class="footer__socials-item">
            <NuxtLink :to="item.link" class="footer__socials-link" target="_blank">
              <NuxtIcon v-if="item.name" :name="`IconSocial${item.name}`" filled />
            </NuxtLink>
          </li>
        </template>
      </ul>
      <div class="footer__bottom">
        <NuxtLink
          v-if="config.privacyPolicy"
          :to="{ name: 'home', params: { chapters: useSlugTransform(config.privacyPolicy) } }"
          class="footer__bottom-link"
        >
          Политика конфиденциальности
        </NuxtLink>
        <NuxtLink class="footer__bottom-link" to="https://coffeestudio.ru/" target="_blank">
          Сделано в CoffeeStudio
        </NuxtLink>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type { MenuItem } from '@/types/menu';
import './styles.scss';
import { useScreenWidth } from '~/composables/useScreenWidth';
import CSimpleFormBlock from '~/components/blocks/simpleFormBlock/CSimpleFormBlock.vue';
import { useSlugTransform, useState } from '#imports';
import type { BlockButtonTrigger, FormModel } from '~/types/pages';
import CButtonTrigger from '~/components/buttonTrigger/CButtonTrigger.vue';
import { yandexRichGoals } from '~/composables/yandexRichGoals';

interface Props {
  menuItems: MenuItem[];
  footerPhone?: string;
  footerEmail?: string;
}

const formFooter: FormModel | undefined = useState<FormModel[]>('forms').value?.find(
  el => el.key === '2fields',
);

const { goalsClickPhone } = yandexRichGoals();

const config = useState('config');

const affiliates = useState('affiliates');

defineProps<Props>();

const { isL } = useScreenWidth();

const buttonForm: BlockButtonTrigger = {
  type: 'form',
  content: '2fields',
};
</script>
