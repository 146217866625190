import DOMPurify from 'isomorphic-dompurify';
import { defineNuxtPlugin } from '#app';

/**
 * Плагин для "стериализации" html кода вставляемого через v-html
 * устранияет XSS уязвимость
 *
 * если нужно очистить html от кода который может навредить используйте директиву `v-sanitize-html`
 */

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('sanitize-html', {
    mounted(el, binding) {
      try {
        el.innerHTML = binding.value
          ? DOMPurify.sanitize(binding.value, { ADD_ATTR: ['target'] })
          : '';
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });
});
