export const yandexRichGoals = () => {
  const goalsFormSuccess = () => {
    if (window && window.ym) {
      window.ym(42281864, 'reachGoal', 'form_success');
    }
  };

  const goalsClickPhone = () => {
    if (window && window.ym) {
      window.ym(42281864, 'reachGoal', 'click_phone');
    }
  };

  return {
    goalsClickPhone,
    goalsFormSuccess,
  };
};
