<template>
  <div class="form-block">
    <div v-if="isModal && successMessage.length > 0">
      <h2 class="text-subheader">Успех!</h2>
      <h3>{{ successMessage }}</h3>
    </div>
    <template v-else>
      <h2 v-if="title.length > 0" class="text-subheader">{{ title }}</h2>
      <h3>{{ props.helpText }}</h3>
      <ClientOnly>
        <form class="form-block__inner" novalidate @submit="execute">
          <div
            v-for="(field, index) in fields"
            :key="`${index}_${field.name}_${field.type}`"
            class="form-block__field"
          >
            <ul v-show="errorFields[field.name]" class="form-block__field-errors">
              <li
                v-for="(error, eIndex) in errorFields[field.name]"
                :key="`form_error_${eIndex}_${error.name}`"
                class="form-block__field-error"
              >
                {{ error.message }}
              </li>
            </ul>
            <ul v-if="serverErrors && serverErrors[field.name]" class="form-block__field-errors">
              <li
                v-for="(error, eIndex) in serverErrors[field.name]"
                :key="`form_error_${eIndex}_${error}`"
                class="form-block__field-error"
              >
                {{ error }}
              </li>
            </ul>
            <Component
              :is="fieldDictionary[field.type].component"
              v-bind="fieldSelectedProps(field, formModel, fieldDictionary)"
              v-model="formModel[field.name]"
            />
          </div>
          <UIButton
            class="form-button"
            type="button"
            label="Отправить"
            @click.stop="onSubmitForm"
          />
          <ul v-show="errorCheckbox.length" class="form-block__field-errors">
            <li class="form-block__field-error">
              {{ errorCheckbox }}
            </li>
          </ul>
          <UICheckbox class="form-block__policy" v-model="checkboxModel">
            Принимаю условия
            <NuxtLink
              :to="{ name: 'home', params: { chapters: useSlugTransform(config.privacyPolicy) } }"
              >политики конфиденциальности
            </NuxtLink>
            и даю разрешение на обработку персональных данных в соответствии с
            <NuxtLink
              :to="{
                name: 'home',
                params: { chapters: useSlugTransform(config.consentPersonalData) },
              }"
              >согласием на обработку персональных данных
            </NuxtLink>
            .
          </UICheckbox>
        </form>
      </ClientOnly>
    </template>

    <UIModal v-model="successModalModel" styled-class="modal-form">
      <div class="form-block">
        <h2 class="text-subheader">Успех!</h2>
        <h3>{{ successMessage }}</h3>
      </div>
    </UIModal>
  </div>
</template>

<script setup lang="ts">
import type { FormField } from '~/types/pages';
import type { Rules } from 'async-validator';
import type { FeedbackProcessResponse } from '~/types/feedback';
import type { SiteSettings } from '~/types/config';
import {
  defineAsyncComponent,
  ref,
  useLazyFetch,
  useRoute,
  useState,
  useSlugTransform,
} from '#imports';
import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator';
import UIButton from '~/components/UI/button/UIButton.vue';
import UICheckbox from '~/components/UI/checkbox/UICheckbox.vue';
import useGoogleRecaptcha from '~/composables/useGoogleRecaptcha';
import UIModal from '~/components/UI/modal/UIModal.vue';
import './styles.scss';
import { yandexRichGoals } from '~/composables/yandexRichGoals';

interface Props {
  title: string;
  formKey: string;
  helpText: string;
  fields: FormField[];
  isModal?: boolean;
}

const input = defineAsyncComponent(() => import('~/components/UI/input/UIInput.vue'));
const checkbox = defineAsyncComponent(() => import('~/components/UI/checkbox/UICheckbox.vue'));
const select = defineAsyncComponent(() => import('~/components/UI/select/UISelect.vue'));
const inputFile = defineAsyncComponent(() => import('~/components/UI/inputFile/UIInputFile.vue'));
const successModalModel = ref(false);

const props = defineProps<Props>();
const emit = defineEmits(['openSuccessModal']);

const fieldDictionary = {
  'django.forms.CharField': {
    component: input,
    type: 'text',
    ruleType: 'string',
  },
  'core.dynamicforms.fields.TextAreaField': {
    component: input,
    type: 'textarea',
    ruleType: 'string',
  },
  'django.forms.IntegerField': {
    component: input,
    type: 'number',
    ruleType: 'integer',
  },
  'django.forms.FloatField': {
    component: input,
    type: 'number',
    ruleType: 'float',
  },
  'django.forms.BooleanField': {
    component: checkbox,
    ruleType: 'boolean',
  },
  'django.forms.EmailField': {
    component: input,
    type: 'email',
    ruleType: 'email',
  },
  'core.dynamicforms.fields.PhoneField': {
    component: input,
    type: 'tel',
    ruleType: 'string',
  },
  'django.forms.DateField': {
    component: input,
    type: 'date',
  },
  'django.forms.TimeField': {
    component: input,
    type: 'time',
  },
  'django.forms.DateTimeField': {
    component: input,
    type: 'dateTime',
  },
  'django.forms.FileField': {
    component: inputFile,
  },
  'django.forms.ImageField': {
    component: inputFile,
  },
  'django.forms.ChoiceField': {
    component: select,
    ruleType: 'string',
  },
  'django.forms.MultipleChoiceField': {
    component: select,
    ruleType: 'array',
  },
};

const formModel = ref<Record<string, any>>({});

const checkboxModel = ref(false);

const fieldSelectedProps = (
  field: FormField,
  model: Record<string, any>,
  dict: Record<string, any>,
) => {
  if (!model[field.name]) {
    model[field.name] = getDefaultFieldValue(field);
  }
  const type = dict[field.type].type;
  const placeholder = field.placeholder;
  const label = field.label;
  const options = field.choices;
  const required = field.required;
  const maxFileSize = field.type === 'django.forms.FileField' ? 20000000 : 5000000;
  const allowedFormats =
    field.type === 'django.forms.FileField'
      ? [
          'application/pdf',
          '.doc',
          '.docx',
          'xml',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ]
      : ['image/png', 'image/jpg', 'image/jpeg'];

  return {
    required,
    type,
    placeholder,
    label,
    options,
    maxFileSize,
    allowedFormats,
    isMultiply: field.type === 'django.forms.MultipleChoiceField',
  };
};

const getDefaultFieldValue = (field: FormField | undefined) => {
  if (!field) {
    return '';
  }

  if (field.type === 'django.forms.BooleanField') {
    return false;
  } else if (
    field.type === 'django.forms.ChoiceField' ||
    field.type === 'django.forms.MultipleChoiceField' ||
    field.type === 'django.forms.FileField' ||
    field.type === 'django.forms.ImageField'
  ) {
    return null;
  }
  return '';
};

const getFieldByName = (fieldName: string) => {
  return props.fields.find(field => field.name === fieldName);
};

const clearForm = () => {
  for (const fieldName in formModel.value) {
    formModel.value[fieldName] = getDefaultFieldValue(getFieldByName(fieldName));
  }
};

const setDefaultValues = () => {
  for (const fieldName in formModel.value) {
    if (!formModel.value[fieldName]) {
      formModel.value[fieldName] = getDefaultFieldValue(getFieldByName(fieldName));
    }
  }
};

const createRules = (): Rules => {
  const rules: Rules = {};
  props.fields.forEach((field: FormField) => {
    if (!rules[field.name]) {
      rules[field.name] = {
        type: fieldDictionary[field.type]?.ruleType || 'any',
        required: field.required,
      };
    }
  });

  return rules;
};

const { errorFields, execute } = useAsyncValidator(formModel, createRules, {
  validateOption: {
    messages: {
      required: () => 'Обязательное поле',
      types: {
        email: () => 'Значение поля должно быть типа example@example.ex',
        integer: () => 'Значение поля должно быть целым числом',
        float: () => 'Значение поля должно быть дробным числом',
      },
    },
  },
  immediate: false,
  manual: true,
});

const route = useRoute();
const { executeRecaptcha } = useGoogleRecaptcha();

const { goalsFormSuccess } = yandexRichGoals();

const isSuccess = ref(false);
const successMessage = ref('');
const errorCheckbox = ref('');
const serverErrors = ref<Record<string, string[]> | null>(null);
const onSubmitForm = async (event: Event) => {
  const { pass } = await execute();

  event.preventDefault();

  serverErrors.value = null;

  if (!checkboxModel.value) {
    errorCheckbox.value = 'Cогласие не принято';
    return;
  } else {
    errorCheckbox.value = '';
  }

  if (pass) {
    formModel.value['referer'] = route.fullPath;

    const formData = new FormData();
    const { token } = await executeRecaptcha(props.formKey);
    for (const item in formModel.value) {
      if (formModel.value[item]) {
        if (Array.isArray(formModel.value[item])) {
          formModel.value[item].forEach((value: string) => {
            formData.append(item, value);
          });
        } else {
          formData.append(item, formModel.value[item]);
        }
      }
    }

    formData.append('g-recaptcha-response', token);

    const { data, error } = await useLazyFetch<FeedbackProcessResponse>(
      `/api/feedback/${props.formKey}`,
      {
        method: 'POST',
        body: formData,
      },
    );

    if (data.value) {
      isSuccess.value = true;

      if (data.value.status === 400) {
        serverErrors.value = data.value.data;
      }

      if (data.value.success_message) {
        goalsFormSuccess();

        if (!props.isModal) {
          successModalModel.value = true;
        }
        successMessage.value = data.value.success_message;
        emit('openSuccessModal');

        window.setTimeout(() => {
          successMessage.value = '';
          if (!props.isModal) {
            successModalModel.value = false;
          }
        }, 5000);

        clearForm();
      }
    }
    if (error.value) {
      // console.log(error.value);
      setDefaultValues();
      serverErrors.value = error.value.data.data;
    }
  }
};

const config = useState<SiteSettings>('config');
</script>
