import { Ref } from 'vue';
import { useMediaQuery } from '@vueuse/core';

const screens: Record<string, string> = {
  xxl: '1640px',
  xl: '1340px',
  l: '1024px',
  m: '768px',
  s: '520px',
  xs: '375px',
};

export const useScreenWidth = () => {
  const queries: { [key: string]: Ref<boolean> } = {};
  for (const query in screens) {
    queries[`is${query.toUpperCase()}`] = useMediaQuery(`(max-width: ${screens[query]})`);
  }
  return queries;
};
