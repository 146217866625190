<template>
  <main class="error">
    <div class="error__wrapper wrapper">
      <div class="error__content">
        <template v-if="error.statusCode === 404">
          <h1 class="error__title">Такой страницы нет</h1>
          <span class="error__txt">
            Ссылка, по которой вы перешли, никуда не ведет. Возможно, в ней была опечатка, или эта
            страница была удалена
          </span>
        </template>

        <template v-else>
          <h1 class="error__title">Ошибка обращения к сервису</h1>
          <span class="error__txt">
            Мы уже исправляем проблему. Попробуйте обновить страницу через некоторое время. Приносим
            извинения за временные неудобства
          </span>
        </template>

        <template v-if="isDev">
          <pre>{{ error.statusMessage || error.message }}</pre>
          <template v-if="error.stack" v-html="error.stack" />
        </template>

        <div class="error__btns">
          <UIButton variant="primary" size="l" @click="handleError">На главную</UIButton>
          <UIButton v-if="error.statusCode !== 404" variant="primary" size="l" @click="updatePage">
            Обновить страницу
          </UIButton>
        </div>
      </div>
      <div class="error__name">{{ error.statusCode }}</div>
    </div>
  </main>
</template>

<script setup>
import { clearError, useRoute, useRuntimeConfig } from '#imports';
import UIButton from '~/components/UI/button/UIButton.vue';

defineProps(['error']);

const envMode = useRuntimeConfig().public.isDev;

const isDev = envMode === 'development';

const handleError = () => clearError({ redirect: '/' });

const route = useRoute();
const updatePage = () => clearError({ redirect: route.path });
</script>

<style lang="scss">
.error {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 400px;
    height: 68vh;
    gap: 24px;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: $media-m) {
      flex-direction: column-reverse;
      justify-content: center;
      min-height: 450px;
    }
  }

  &__content {
    max-width: 670px;
  }

  &__title {
    margin-bottom: 24px;
    @media (max-width: $media-m) {
      text-align: center;
      margin-bottom: 8px;
    }
  }

  &__txt {
    @include typography-secondary;
    @media (max-width: $media-m) {
      text-align: center;
      display: block;
    }
  }

  &__btns {
    display: flex;
    gap: 24px;
    margin-top: 56px;
    @media (max-width: $media-l) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: $media-m) {
      margin: 24px auto 0 auto;
      align-items: center;
    }
  }

  .button {
    display: flex;
    width: 200px;
    @media (max-width: $media-l) {
      width: 242px;
    }
  }

  &__name {
    font-size: 250px;
    font-weight: 700;
    line-height: 300px;

    @media (max-width: $media-l) {
      font-size: 180px;
      line-height: 1.2;
    }
    @media (max-width: $media-m) {
      font-size: 140px;
    }
    @media (max-width: $media-s) {
      font-size: 110px;
    }
  }
}
</style>
