<template>
  <div class="image-text-block-inner">
    <div class="wrapper">
      <div :class="{ 'flex-reverse': isReverse }" class="image-text-block">
        <h2 v-if="title && isM" class="text-subheader">{{ title }}</h2>
        <div class="image-text-block__content flex-11a">
          <h2 v-if="title && !isM" class="text-subheader">{{ title }}</h2>
          <CTextBlock :text="text" />
          <CButtonTrigger
            v-if="buttonTrigger && buttonText"
            :button-title="buttonText"
            :button-trigger="buttonTrigger"
          />
        </div>
        <div
          class="image-text-block__image flex-00a"
          :class="{ 'image-text-block__image--rounded': imageIsRounded }"
        >
          <UIPictureTag
            v-if="image.source"
            :is-visible="isVisibleImage"
            :image-webp="`${url}${image.webp}`"
            :image-original="`${url}${image.source}`"
            :image-seo="imageSeo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BlockButtonTrigger, ImageItem, ImageSEO } from '@/types/pages';
import { useRuntimeConfig, useScreenWidth } from '#imports';
import './styles.scss';
import CTextBlock from '~/components/blocks/textBlock/CTextBlock.vue';
import UIPictureTag from '~/components/UI/picture/UIPictureTag.vue';
import CButtonTrigger from '~/components/buttonTrigger/CButtonTrigger.vue';

interface Props {
  title?: string;
  image: ImageItem;
  imageSeo: ImageSEO;
  text: string;
  isReverse?: boolean;
  imageIsRounded: boolean;
  buttonText: string | null;
  buttonTrigger: BlockButtonTrigger | null;
  isVisibleImage?: boolean;
}

const url = useRuntimeConfig().public.siteUrl;

const { isM } = useScreenWidth();

defineProps<Props>();
</script>
