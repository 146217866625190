<template>
  <section class="rating">
    <div class="wrapper">
      <h2 v-if="name" class="rating__title">{{ name }}</h2>
      <CTextBlock v-if="text" class="rating__desc" :text="text" />
      <ClientOnly>
        <Swiper
          class="rating__slider"
          :modules="[Pagination]"
          :breakpoints="customBreakpoints || breakpoints"
          :slides-per-view="slidesPerView || 1.8"
          :space-between="24"
          :auto-height="false"
          :centered-slides="true"
          pagination
          :loop="media.length > 2"
        >
          <SwiperSlide
            v-for="(slide, index) in media"
            :key="`rating_slide_${index}`"
            class="rating__slider-slide"
          >
            <UIPictureTag
              :is-visible="true"
              :image-seo="slide.image_seo"
              :image-webp="`${url}${slide.image.webp}`"
              :image-original="`${url}${slide.image.source}`"
            />
            <div class="rating__slider-txt">Рейтинг</div>
            <div class="rating__slider-value">{{ slide.value }}</div>
          </SwiperSlide>
        </Swiper>
      </ClientOnly>
    </div>
  </section>
</template>

<script setup lang="ts">
import CTextBlock from '~/components/blocks/textBlock/CTextBlock.vue';
import type { RatingItem } from '~/types/pages';
import { Pagination, type SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import './styles.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useRuntimeConfig } from '#imports';
import UIPictureTag from '~/components/UI/picture/UIPictureTag.vue';

interface Props {
  name?: string;
  text?: string;
  media: RatingItem[];
  customBreakpoints?: SwiperOptions['breakpoints'];
  slidesPerView?: number;
}

defineProps<Props>();

const url = useRuntimeConfig().public.siteUrl;

const breakpoints = {
  1024: {
    slidesPerView: 4,
    spaceBetween: 24,
    centeredSlides: false,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 24,
    centeredSlides: false,
  },
  520: {
    slidesPerView: 2,
    spaceBetween: 24,
  },
};
</script>

<style scoped></style>
