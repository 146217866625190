<template>
  <div class="specialist-card">
    <div
      ref="card"
      class="specialist-card__img"
      :class="{ 'specialist-card__img--video': link.length > 0 }"
      @click="openMediaModal(0)"
    >
      <UIPictureTag
        :image-original="`${url}${image.source}`"
        :image-seo="image_seo"
        :image-webp="`${url}${image.webp}`"
        is-visible
      />
    </div>
    <NuxtLink
      :to="{ name: 'home', params: { chapters: useSlugTransform(full_slug) } }"
      class="specialist-card__name"
    >
      {{ full_name }}
    </NuxtLink>
    <div v-if="experience_from" class="specialist-card__experience">
      Стаж {{ useCalculateAge(experience_from) }}
    </div>
    <div v-if="preview_text" class="specialist-card__desc">
      {{ preview_text }}
    </div>
    <UIButton
      class="specialist-card__button"
      variant="secondary"
      size="s"
      label="Подробнее о враче"
    >
      Подробнее о враче
      <NuxtIcon name="IconArrowThinRight" />
    </UIButton>
  </div>
</template>

<script setup lang="ts">
import { useCalculateAge } from '~/composables/UseCalculateAge';
import type { ImageItem, ImageSEO } from '~/types/pages';
import './styles.scss';
import { computed, onUnmounted, useMediaModal, useRuntimeConfig, useSlugTransform } from '#imports';

interface Props {
  id: number;
  full_name: string;
  experience_from?: string;
  link: string;
  image: ImageItem;
  image_seo: ImageSEO;
  preview_text: string;
  full_slug: string;
}

const url = useRuntimeConfig().public.siteUrl;
const props = defineProps<Props>();

const dataSource = computed(() => ({
  html: `<iframe src="${props?.link}?rel=0"
                    frameborder="0"
                    allow="
                     accelerometer;
                     autoplay;
                     clipboard-write;
                     encrypted-media;
                     gyroscope;
                     picture-in-picture;
                     web-share"
                    allowfullscreen>
                   </iframe>`,
}));

const { openMediaModal, destroyMediaModal } = useMediaModal([dataSource.value]);

onUnmounted(() => destroyMediaModal());
</script>
