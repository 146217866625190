import type { Breadcrumb, CaptureContext, Primitive, User } from '@sentry/types';
import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin, useRouter, useRuntimeConfig } from '#app';

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter();

  const {
    public: { sentry },
  } = useRuntimeConfig();

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    autoSessionTracking: true,
    release: `ao-stom@${new Date(Date.now()).toDateString()}`,
    integrations: [
      Sentry.browserTracingIntegration({ router, routeLabel: 'path' }),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.httpClientIntegration(),
      Sentry.reportingObserverIntegration(),
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    allowUrls: ['https://ao-stom.ru', 'http://ao-stom-nuxt.servertest.pro/'],
    tracePropagationTargets: ['https://ao-stom.ru', 'http://ao-stom-nuxt.servertest.pro/'],
  });

  nuxtApp.vueApp.config.errorHandler = (err, context) => {
    Sentry.withScope(scope => {
      scope.setExtra('context', context);
      Sentry.captureException(err);
    });
  };

  nuxtApp.hook('app:error', err => {
    Sentry.captureException(err);
  });
});
