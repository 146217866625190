import { RouterConfig } from '@nuxt/schema';
import EntryPage from '~/pages/EntryPage.vue';

const routes = [
  // {
  //   name: 'searchPage',
  //   path: '/search',
  //   component: () => import('@/pages/SearchPage.vue').then(r => r.default || r),
  // },
  {
    name: 'home',
    path: '/:chapters*',
    component: EntryPage,
  },
];

export default <RouterConfig>{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  routes: _routes => {
    return routes;
  },
};
