<template>
  <div class="wrapper articles">
    <CBreadcrumbs
      :current-page-name="pageHeader"
      :is-light="false"
      :parent-sections="breadcrumbs"
    />

    <div v-if="isShowFilters || data.is_filters_displayed" class="articles__filters">
      <UISelect
        v-model="doctorsFilterModel"
        :options="doctorsOptions"
        placeholder="Выберете врача"
      ></UISelect>
      <UISelect
        v-model="servicesFilterModel"
        :options="servicesOptions"
        placeholder="Выберете услугу"
      ></UISelect>
      <UISelect
        v-model="officesFilterModel"
        :options="officesOptions"
        placeholder="Выберете филиал">
      </UISelect>
    </div>

    <h1 class="articles__title">{{ pageHeader }}</h1>

    <template v-if="cardList.results.length > 0">
      <div class="articles__cards">
        <CArticleCard
          v-for="(page, index) in cardList.results"
          :key="`article_subpage_${page.id}_${index}`"
          :title="page.title"
          :image="page.image"
          :image-seo="{
            image_alt: page.title,
            image_title: page.title,
          }"
          :text="page.preview_text"
          :slug="page.slug"
          :date="page.publish_from"
        />
      </div>
      <CPagination
        v-if="cardList?.count > cardList.results.length"
        :pagination="{
          page: Number(currentPage),
          totalPages: totalPages,
        }"
      />
    </template>
    <template v-else>
      <h2 class="articles__no-results">По данным фильтрам нет, данных. Попробуйте их изменить</h2>
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  inject,
  ref,
  useAsyncData,
  useHead,
  useSeoMeta,
  useRuntimeConfig,
  createError,
  useRouter, useState,
} from '#imports';
import CBreadcrumbs from '@/components/breadcrumbs/CBreadcrumbs.vue';
import type { ClientBreadcrumbsItem } from '@/types/client/pages';
import CArticleCard from '~/components/sectionBlocks/articleCard/CArticleCard.vue';
import CPagination from '~/components/sectionBlocks/pagination/CPagination.vue';
import type { SiteSetting } from '~/types/config';
import type { ErrorResponse } from '~/server/utils/serverFetch';
import type {
  ApiPageExtendedResponse,
  ApiPageResponse, BlockOffice,
  FiltersCatalogsResponse,
} from '~/types/pages';
import { isErrorResponse } from '~/server/utils/serverError';
import type { ClientSelectOption } from '~/types/client/components';
import {off} from "dom7";

const props = defineProps({
  isShowFilters: {
    type: Boolean,
    default: false,
  },
  id: {
    type: Number,
  },
});

const pageCount = inject<SiteSetting>('pageCount');

const pageHeader = ref<string>();
const breadcrumbs = ref<ClientBreadcrumbsItem[]>();
const route = useRouter().currentRoute.value;

const doctorsFilterModel = ref(null);
const servicesFilterModel = ref(null);
const officesFilterModel = ref(null);
const doctorsOptions = ref<ClientSelectOption[]>([]);
const servicesOptions = ref<ClientSelectOption[]>([]);
const officesOptions = ref<ClientSelectOption[]>([]);
const offices = useState<BlockOffice['offices']>('affiliates')

const slug = computed(() => {
  if (route.path === '/') {
    return 'index';
  }

  if (route.params && route.params.chapters) {
    return (route.params.chapters as string[]).join('/');
  }
});
const { siteUrl } = useRuntimeConfig().public;

const { data, error } = await useAsyncData<ErrorResponse | ApiPageExtendedResponse>(
  'getBlocksResponse',
  () => {
    return $fetch(`/api/pages/extended`, {
      method: 'GET',
      params: {
        slug: slug.value,
      },
    });
  },
  {
    pick: ['breadcrumbs', 'seo', 'og', 'is_filters_displayed'],
  },
);

if (error.value) {
  throw createError(error.value);
}

const currentPage = computed(() => route.query?.page || 1);

if (props.isShowFilters) {
  const { data: filters } = await useAsyncData<{
    doctors: FiltersCatalogsResponse;
    services: FiltersCatalogsResponse;
  }>('sectionFilters', async () => {
    const [services, doctors] = await Promise.all([
      $fetch<FiltersCatalogsResponse>(`/api/services/filters`, {
        method: 'GET',
      }),
      $fetch<FiltersCatalogsResponse>(`/api/doctors/filters`, {
        method: 'GET',
      }),
    ]);

    return { doctors, services };
  });

  officesOptions.value = offices.value.map(el => ({value: el.id, title: el.title}))
  doctorsOptions.value = filters.value?.doctors.map(el => ({ value: el.id, title: el.title }));
  servicesOptions.value = filters.value?.services.map(el => ({ value: el.id, title: el.title }));
}

const { data: cardList, error: errorList } = await useAsyncData<ErrorResponse | ApiPageResponse>(
  'getListResponse',
  () => {
    return $fetch(`/api/pages/list`, {
      method: 'GET',
      params: {
        slug: slug.value,
        page_size: pageCount?.value || 8,
        ordering: 'sort',
        search: '',
        page: currentPage.value,
        parentId: props.id,
        services: servicesFilterModel.value,
        doctors: doctorsFilterModel.value,
        offices: officesFilterModel.value,
      },
    });
  },
  {
    watch: [currentPage, doctorsFilterModel, servicesFilterModel, officesFilterModel],
  },
);

const totalPages = computed(() =>
  Math.ceil((cardList.value as ApiPageResponse)?.count / pageCount?.value),
);

if (data.value) {
  if (!isErrorResponse(data.value)) {
    pageHeader.value = data.value.seo.h1;
    breadcrumbs.value = data.value.breadcrumbs;

    useHead({
      title: data.value.title || data.value.seo.title,
    });

    useSeoMeta({
      description: data.value.seo.description,
      keywords: data.value.seo.keywords,
      ogTitle: data.value.og.title,
      ogDescription: data.value.og.description,
      ogImage: data.value?.og.image.source
        ? `${siteUrl}${data.value.og.image.source}`
        : '/android-chrome-192x192.png',
      ogUrl: route.fullPath,
    });
  }
}

if (errorList.value) {
  throw createError(errorList.value);
}
</script>

<style lang="scss">
.select__list {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.select__option {
  word-break: break-word;
}

.articles {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin-bottom: 64px;

  &__filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    .select {
      min-width: 300px;
    }

    @media (max-width: $media-m) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__title {
    margin-top: 16px;
  }

  &__no-results {
    padding: 24px 0;
    text-align: center;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;
    row-gap: 40px;
    margin: 40px 0;

    @media (max-width: $media-l) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $media-m) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $media-s) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
