<template>
  <label class="field-check flex gap-8 flex-ai-center">
    <input
      v-model="value"
      class="field-check__input"
      type="checkbox"
      @change="$emit('update:modelValue', value)"
    />
    <span class="field-check__icon flex flex-ai-center flex-jc-center">
      <NuxtIcon v-if="value" name="IconCheckbox" filled />
    </span>
    <span class="field-check__title">
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script lang="ts" setup>
import './styles.scss';
import { computed } from '#imports';

interface Props {
  modelValue: boolean;
  label?: string;
}

const props = defineProps<Props>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
});
</script>
