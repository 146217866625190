<template>
  <div class="header-services">
    <div class="header-services__wrapper">
      <div class="header-services__col">
        <div class="header-services__mobile">
          <NuxtIcon
            class="header-services__mobile-close"
            name="IconCancel"
            @click="$emit('onClose')"
          />
        </div>
        <div class="header-services__title">Услуги клиники</div>
        <div class="tabs">
          <div class="tabs__wrapper">
            <div class="tabs__header wrapper">
              <div
                class="tabs__btn"
                :class="{ active: openTab === 'adult' }"
                @click="changeOpenTab('adult')"
              >
                <span>Для взрослых</span>
              </div>
              <div
                class="tabs__btn"
                :class="{ active: openTab === 'child' }"
                @click="changeOpenTab('child')"
              >
                <span>Для детей</span>
              </div>
            </div>
            <div class="tabs__content">
              <div v-if="openTab === 'adult'" class="tabs__content-item">
                <div
                  v-for="(item, index) in categorizeItems.adultList"
                  :key="`services_adult_${index}`"
                  class="services-item"
                  :class="{ active: currentItemId === item.id }"
                  @click="setChildrenServices(item)"
                >
                  <div class="services-item__header">
                    <div class="services-item__icon">
                      <NuxtIcon v-if="item.icon" :name="useServicesIconName(item.icon)" filled></NuxtIcon>
                    </div>
                    <NuxtLink
                      :to="{
                        name: 'home',
                        params: { chapters: useSlugTransform(item.full_slug) },
                      }"
                      class="services-item__title"
                      >{{ item.title }}
                    </NuxtLink>
                    <div class="services-item__arrow">
                      <NuxtIcon name="IconAngleRight"></NuxtIcon>
                    </div>
                  </div>
                  <div class="services-item__childs">
                    <NuxtLink
                      v-for="(child, cIndex) in item.children"
                      :key="`${index}_${cIndex}`"
                      :to="{
                        name: 'home',
                        params: { chapters: useSlugTransform(child.full_slug) },
                      }"
                      >{{ child.title }}
                    </NuxtLink>
                  </div>
                </div>
              </div>
              <div v-if="openTab === 'child'" class="tabs__content-item">
                <div
                  v-for="(item, index) in categorizeItems.childList"
                  :key="`services_adult_${index}`"
                  class="services-item"
                  :class="{ active: currentItemId === item.id }"
                  @click="setChildrenServices(item)"
                >
                  <div class="services-item__header">
                    <div class="services-item__icon">
                      <NuxtIcon v-if="item.icon" :name="useServicesIconName(item.icon)" filled></NuxtIcon>
                    </div>
                    <NuxtLink
                      :to="{
                        name: 'home',
                        params: { chapters: useSlugTransform(item.full_slug) },
                      }"
                      class="services-item__title"
                      >{{ item.title }}
                    </NuxtLink>
                    <div class="services-item__arrow">
                      <NuxtIcon name="IconAngleRight"></NuxtIcon>
                    </div>
                  </div>
                  <div class="services-item__childs">
                    <NuxtLink
                      v-for="(child, cIndex) in item.children"
                      :key="`${index}_${cIndex}`"
                      :to="{
                        name: 'home',
                        params: { chapters: useSlugTransform(child.full_slug) },
                      }"
                      >{{ child.title }}
                    </NuxtLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-services__col header-services__col-child" :class="{ isOpen: isOpenChild }">
        <div class="header-services__mobile">
          <UIButton size="s" label="Назад" @click="isOpenChild = false" />
        </div>
        <div class="header-services__child-category">
          <div v-if="childrenTitle" class="header-services__title">{{ childrenTitle }}</div>
          <div class="header-services__child-inner">
            <NuxtLink
              v-for="(child, childIndex) in childrenServices"
              :key="childIndex"
              :to="{ name: 'home', params: { chapters: useSlugTransform(child.full_slug) } }"
              class="services-child"
            >
              {{ child.title }}
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, useServicesIconName, useSlugTransform, useState } from '#imports';
import UIButton from '~/components/UI/button/UIButton.vue';
import type { ServicesResults } from '~/types/pages';

defineEmits(['onClose']);

const servicesCatalog = useState<ServicesResults[]>('servicesCatalog');

const childrenServices = ref<ServicesResults[]>([]);
const childrenTitle = ref<string | null>(null);
const currentItemId = ref<null | number>(null);

const categorizeItems = computed(() => {
  const adultList: ServicesResults[] = [];
  const childList: ServicesResults[] = [];

  if (servicesCatalog.value) {
    servicesCatalog.value.forEach(item => {
      if (item.age === 'adult') {
        adultList.push(item);
      } else if (item.age === 'children') {
        childList.push(item);
      }
    });
  }

  return {
    adultList,
    childList,
  };
});

const openTab = ref('adult');

const isOpenChild = ref(false);

const setChildrenServices = (item: ServicesResults) => {
  childrenServices.value = item.children;
  childrenTitle.value = item.title;
  currentItemId.value = item.id;

  isOpenChild.value = true;
};

const changeOpenTab = (tabName: string) => (openTab.value = tabName);
</script>

<style scoped lang="scss">
.header-services {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1200px;
  width: 100%;
  height: 656px;
  margin: auto;
  z-index: 99;
  background-color: $color-bg-primary;
  border-radius: 0px 0px 32px 32px;
  padding: 24px;
  @include boxShadow();

  @media (max-width: $media-l) {
    transform: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    height: auto;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    overflow: auto;
  }

  &__mobile {
    display: none;
    padding: 0 12px;

    @media (max-width: $media-l) {
      display: block;
    }
  }

  &__mobile-close {
    font-size: 32px;
    color: $color-icon-accent;
    margin: 0 0 0 auto;
    display: block;
    width: 32px;
  }

  &__title {
    color: $color-typography-accent;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px; /* 120% */
    padding: 0 8px;
    margin-bottom: 16px;
    flex: 0 0 auto;

    @media (max-width: $media-l) {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    height: 100%;

    @media (max-width: $media-l) {
      display: block;
    }
  }

  &__col {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: $media-l) {
      flex: 1 1 100%;
    }

    &:first-child {
      border-right: 1px solid #e3bc82;

      @media (max-width: $media-l) {
        border-right: 0;
      }
    }

    .tabs {
      margin-top: 0 !important;
      flex: 1 1 100%;
      overflow: hidden;
      padding-right: 12px;

      @media (max-width: $media-l) {
        padding-right: 0;
      }

      &__wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__header {
        flex: 0 0 auto;
        display: flex;
        gap: 38px;
        padding: 16px 19px 0;
        overflow-x: auto;
      }

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;

        @include btn--s;

        background-color: $color-bg-invert;
        width: 100%;
        text-align: center;
        padding: 11px 24px;
        min-height: 46px;
        border-radius: 16px 16px 0px 0px;
        color: $color-btn-text-invert;
        position: relative;
        box-shadow: 0px -5px 10px rgba(0, 35, 59, 0.1);
        white-space: nowrap;
        cursor: pointer;

        @include transition();

        @media (max-width: $media-l) {
          padding: 10px;
          min-height: auto;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
        }

        &:before {
          content: '';
          display: block;
          width: 19px;
          height: 19px;
          position: absolute;
          background-image: url('~/assets/icons/tabs-btn-icon-invert.svg');
          bottom: 0;
          left: -19px;

          @include transition();
        }

        &:after {
          content: '';
          display: block;
          width: 19px;
          height: 19px;
          position: absolute;
          background-image: url('~/assets/icons/tabs-btn-icon-invert.svg');
          bottom: 0;
          transform: rotate(90deg);
          left: auto;
          right: -19px;

          @include transition();
        }

        &.active {
          background-color: $color-theme-primary;

          @include btn--l;

          padding: 11px 24px;
          color: $color-btn-text-primary;

          @media (max-width: $media-m) {
            @include btn--s;

            padding: 10px;

            .icon {
              @media (max-width: $media-m) {
                transform: rotate(90deg);
              }
            }
          }

          &:before,
          &:after {
            background-image: url('~/assets/icons/tabs-btn-icon-white.svg');
          }
        }
      }

      &__content {
        flex: 1 1 auto;
        overflow: auto;
        width: 100%;
        border-radius: 0 0 40px 40px;
        background-color: $color-theme-primary;
        padding: 40px 32px;
        padding-right: 36px;

        @include scrollbar;

        @media (max-width: $media-l) {
          padding: 16px 0;
          border-radius: 0;
        }
      }
    }
  }

  &__col-child {
    @media (max-width: $media-l) {
      display: none;
    }
  }

  &__child-category {
    padding: 0 16px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media (max-width: $media-xl) {
    }
  }

  &__child-inner {
    flex: 1 1 100%;
    overflow: auto;

    @include scrollbar;
  }

  .services-item {
    padding: 16px;
    border-radius: 16px;
    background: $color-theme-primary;
    box-shadow: 0 0 10px 0 rgba(49, 47, 59, 0.1);
    margin-bottom: 16px;
    color: $color-typography-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    //cursor: pointer;

    &__header {
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: 16px;

      @media (max-width: $media-l) {
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 16px;
      }
    }

    @include transition();

    &:last-child {
      margin-bottom: 0;
    }

    &__icon {
      width: 40px;
      height: 40px;
      gap: 16px;
      flex: 0 0 40px;
      font-size: 40px;
    }

    &__title {
      flex: 0 1 auto;
      color: $color-typography-primary;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
    }

    &__arrow {
      margin-left: auto;
      cursor: pointer;
      flex: 0 0 40px;
      border-radius: 56px;
      background: $color-btn-primary-normal;
      padding: 6px 12px;
      @include transition();
      opacity: 0;
      font-size: 16px;

      @media (max-width: $media-l) {
        opacity: 1;
      }

      svg {
        margin-bottom: 0;
      }
    }

    &:hover {
      background-color: $color-bg-secondary;

      .services-item__arrow {
        opacity: 1;
      }
    }

    &.active {
      background-color: #e3bc82;

      @media (max-width: $media-l) {
        background-color: transparent;
      }
    }

    &__childs {
      display: none;

      @media (max-width: $media-l) {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 8px 0;
        align-items: flex-start;
      }

      a {
        color: #4f4f4f;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-decoration: none;
        padding: 0;
      }
    }
  }

  .services-child {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding: 16px 0;
    border-bottom: 1px solid rgba(79, 79, 79, 0.05);
    text-decoration: none;
    display: block;
    color: $color-typography-primary;

    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
