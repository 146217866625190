import { VueReCaptcha } from 'vue-recaptcha-v3';
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: '6LdzWqUpAAAAAJ2F2Gj-XHJ7rk5zk8B8eIeY8QYd',
    loaderOptions: {
      autoHideBadge: false,
      useRecaptchaNet: true,
      explicitRenderParameters: {
        badge: 'bottomleft',
        size: 'invisible',
      },
    },
  });
});
