<template>
  <div class="cookie-policy" v-if="!cookiesPolicy" role="alert">
    <noindex>
      <div class="wrapper">
        <div class="cookie-policy__wrapper">
          <div class="cookie-policy__text">
            <p>
              Мы используем куки на нашем сайте чтобы сделать его удобнее для вас.
              <NuxtLink
                :to="{ name: 'home', params: { chapters: useSlugTransform(config.privacyPolicy) } }"
                >Подробнее
              </NuxtLink>
            </p>
          </div>
          <UIButton variant="secondary" size="s" label="Хорошо" @click="cookiesPolicy = true" />
        </div>
      </div>
    </noindex>
  </div>
</template>

<script setup lang="ts">
import { useCookie, useState, useSlugTransform } from '#imports';
import type { ClientConfigResponse } from '~/types/client/init';
import './styles.scss';

const DAY_IN_SECONDS = 86400;
const DAY_IN_WEEKS = DAY_IN_SECONDS * 1000 * 7;

const expires = new Date(Date.now() + DAY_IN_WEEKS);

const cookiesPolicy = useCookie<boolean>('hideCookiesPanel', {
  expires: expires,
});

cookiesPolicy.value = cookiesPolicy.value || false;

const config = useState<ClientConfigResponse>('config');
</script>
