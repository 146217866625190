import validate from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45index_45global from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/middleware/redirectIndex.global.ts";
import trailing_45slash_45global from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/middleware/trailingSlash.global.ts";
import manifest_45route_45rule from "/var/www/ao-stom/data/www/ao-stom.ru-nuxt/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45index_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}