<template>
  <div class="articles__card">
    <NuxtLink
      class="articles__link"
      type="_self"
      :to="{ name: 'home', params: { chapters: useSlugTransform(slug) } }"
    >
    </NuxtLink>
    <UIPictureTag
      :image-original="image.source ? `${url}${image.source}` : image.source"
      :image-seo="imageSeo"
      :image-webp="image.webp ? `${url}${image.webp}` : image.webp"
    />
    <div class="articles__date-wrapper">
      <span>{{ formattedDate }}</span>
    </div>
    <h3 class="articles__card-title">{{ title }}</h3>
    <p v-if="text" class="articles__text">
      {{ text }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig, useSlugTransform } from '#imports';
import type { ImageItem, ImageSEO } from '~/types/pages';
import './styles.scss';
import { useDateFormat } from '@vueuse/core';

interface Props {
  slug: string;
  image: ImageItem;
  imageSeo: ImageSEO;
  title: string;
  text: string;
  date: string;
}

const props = defineProps<Props>();

const formattedDate = useDateFormat(props.date, 'DD.MM.YYYY', { locales: 'ru-ru' });
const formattedTime = useDateFormat(props.date, 'hh:mm', { locales: 'ru-ru' });

const url = useRuntimeConfig().public.siteUrl;
</script>

<style scoped></style>
