<template>
  <section class="services-main">
    <div class="wrapper">
      <h2 v-if="title" class="services-main__title">{{ title }}</h2>
      <div class="services-main__content">
        <div class="services-main__categories">
          <div
            v-if="adultServices.length > 0"
            class="services-main__categorie services-main__categorie--old"
            :class="{ active: currentTab === 1 }"
            @click="setCurrentTab(1)"
          >
            <img class="services-main__categorie-img" :src="`${url}${adultImage}`" alt="" />
            <span class="services-main__categorie-title">Для взрослых</span>
          </div>
          <div
            v-if="childrenServices.length > 0"
            class="services-main__categorie services-main__categorie--young"
            :class="{ active: currentTab === 2 }"
            @click="setCurrentTab(2)"
          >
            <img class="services-main__categorie-img" :src="`${url}${kidsImage}`" alt="" />
            <span class="services-main__categorie-title">Для детей</span>
          </div>
        </div>
        <div class="services-main__services">
          <h3 class="services-main__sub-title">
            {{ currentTab === 1 ? 'Для взрослых' : 'Для детей' }}
          </h3>
          <div class="services-main__tabs">
            <span
              v-if="adultServices.length > 0"
              class="services-main__tab"
              :class="{ active: currentTab === 1 }"
              @click="setCurrentTab(1)"
            >
              Взрослые
            </span>
            <span
              v-if="childrenServices.length > 0"
              class="services-main__tab"
              :class="{ active: currentTab === 2 }"
              @click="setCurrentTab(2)"
            >
              Дети
            </span>
          </div>
          <ul class="services-main__list">
            <template v-if="currentTab === 1">
              <keep-alive>
                <li v-for="(item, index) in adultServices" :key="index">
                  <!--                    todo поправить на :to-->
                  <NuxtLink
                    class="services-main__item"
                    :to="{ name: 'home', params: { chapters: useSlugTransform(item.full_slug) } }"
                  >
                    <NuxtIcon
                      v-if="item.icon"
                      class="services-main__item-img"
                      :name="useServicesIconName(item.icon)"
                      filled
                    />
                    <span class="services-main__text">
                      {{ item?.title }}
                      <i class="icon icon--arrow-down"></i>
                    </span>
                  </NuxtLink>
                </li>
              </keep-alive>
            </template>
            <template v-if="currentTab === 2">
              <keep-alive>
                <li v-for="(item, index) in childrenServices" :key="index">
                  <NuxtLink
                    class="services-main__item"
                    :to="{ name: 'home', params: { chapters: useSlugTransform(item.full_slug) } }"
                  >
                    <NuxtIcon
                      v-if="item.icon"
                      class="services-main__item-img"
                      :name="useServicesIconName(item.icon)"
                      filled
                    />
                    <span class="services-main__text">
                      {{ item?.title }}
                      <i class="icon icon--arrow-down"></i>
                    </span>
                  </NuxtLink>
                </li>
              </keep-alive>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { CategoryAgeEnum, type ServiceCatalogCategoryItem } from '~/types/pages';
import './styles.scss';
import {
  computed,
  useRuntimeConfig,
  inject,
  ref,
  useServicesIconName,
  useSlugTransform,
} from '#imports';

interface Props {
  title: string;
  categories: ServiceCatalogCategoryItem[];
}

const props = defineProps<Props>();

const adultServices = computed(() =>
  props.categories.filter(el => el.age === CategoryAgeEnum.ADULT),
);
const childrenServices = computed(() =>
  props.categories.filter(el => el.age === CategoryAgeEnum.CHILDREN),
);

const currentTab = ref(1);

const url = useRuntimeConfig().public.siteUrl;
const kidsImage = inject('serviceCatalogKids');
const adultImage = inject('serviceCatalogAdult');

const setCurrentTab = (tab: number) => (currentTab.value = tab);
</script>
