<template>
  <div ref="select" class="select">
    <div ref="selectControl" class="select__control flex gap-8" @click.prevent="openSelect">
      <div class="select__value flex-11a">
        <template v-if="currentValueNames">
          <template v-if="isMultiply">
            {{ currentValueNames[0] }}
            <template v-if="Array.isArray(currentValueNames) && currentValueNames.length > 1">
              + {{ currentValueNames.length - 1 }} эл.
            </template>
          </template>
          <template v-else>{{ currentValueNames }}</template>
        </template>
        <div v-else class="select__value-placeholder">{{ placeholder }}</div>
      </div>
      <div class="select__trigger flex-00a">
        <NuxtIcon v-if="currentValueNames" name="IconCross" @click.stop="onClear" />
        <NuxtIcon name="IconChevronVerticalSmall" />
      </div>
    </div>
    <teleport to="body">
      <div v-if="isOpen" ref="selectList" class="select__list">
        <div class="select__search">
          <UIInput v-if="isSearch" v-model="searchText" placeholder="Поиск" type="text" />
        </div>
        <div
          v-for="(option, index) in options"
          :key="`${index}_${option.value}`"
          class="select__option"
          :class="{
            'select__option--selected': hasSelectedOptions(option.value),
            'select__option--selected-multi': hasSelectedOptions(option.value) && isMultiply,
          }"
          @click="onSelect(option)"
        >
          <div v-if="isMultiply" class="select__option--multi">
            <div class="select__option-icon">
              <NuxtIcon v-if="hasSelectedOptions(option.value)" name="IconCheckbox" filled />
            </div>
            {{ option.title }}
          </div>

          <template v-else>{{ option.title }}</template>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref, watch } from '#imports';
import UIInput from '@/components/UI/input/UIInput.vue';
import './styles.scss';
import type { ClientSelectOption } from '@/types/client/components';
import { onClickOutside, useElementBounding } from '@vueuse/core';

interface Props {
  modelValue: string | number | (string | number)[] | null;
  placeholder: string;
  isMultiply?: boolean;
  isSearch?: boolean;
  options: ClientSelectOption[];
}

const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);
const currentValues = ref(props.modelValue);
const currentValueNames = ref<string | number | (string | number)[] | null>(null);
const searchText = ref('');
const isOpen = ref(false);
const selectControl = ref<HTMLElement | null>(null);
const selectList = ref<HTMLElement | null>(null);
const select = ref<HTMLElement | null>(null);

const onSelect = (option: ClientSelectOption) => {
  if (props.isMultiply) {
    if (!currentValues.value) {
      currentValues.value = new Array(option.value);
      currentValueNames.value = new Array(option.title);
      emit('update:modelValue', currentValues.value);
    } else if (Array.isArray(currentValues.value) && Array.isArray(currentValueNames.value)) {
      const indexValue = currentValues.value.indexOf(option.value);

      if (indexValue > -1) {
        currentValues.value.splice(indexValue, 1);
        currentValueNames.value.splice(indexValue, 1);

        if (currentValueNames.value.length === 0 && currentValues.value.length === 0) {
          onClear();
        }
      } else {
        currentValues.value.push(option.value);
        currentValueNames.value.push(option.title);
      }
      emit('update:modelValue', currentValues.value);
    }
  } else {
    currentValues.value = option.value;
    currentValueNames.value = option.title;
    emit('update:modelValue', currentValues.value);
    isOpen.value = false;
  }
};

const onClear = () => {
  currentValues.value = null;
  currentValueNames.value = null;
  emit('update:modelValue', currentValues.value);
};

const hasSelectedOptions = (value: string | number): boolean => {
  if (Array.isArray(currentValues.value)) {
    return currentValues.value.includes(value);
  }

  return currentValues.value === value;
};

const setPositionSelectList = (isOpen: boolean) => {
  if (isOpen && selectList.value) {
    const {
      top: topSelectControl,
      right: rightSelectControl,
      width: widthSelectControl,
      height: heightSelectControl,
    } = useElementBounding(selectControl);

    selectList.value.style.top = `${topSelectControl.value + heightSelectControl.value}px`;
    selectList.value.style.left = `${rightSelectControl.value - widthSelectControl.value}px`;
    selectList.value.style.width = `${widthSelectControl.value}px`;
  }
};

const openSelect = async () => {
  if (!isOpen.value) {
    isOpen.value = true;
  } else {
    isOpen.value = false;
  }
  await nextTick(() => setPositionSelectList(isOpen.value));
};

onClickOutside(selectList, event => {
  if (event.target?.parentNode?.className !== selectControl.value?.className) {
    if (selectList.value) {
      isOpen.value = false;
    }
  }
});

onMounted(() => {
  window.addEventListener('resize', () => setPositionSelectList(isOpen.value));
  window.addEventListener('scroll', () => setPositionSelectList(isOpen.value));
});

onUnmounted(() => {
  window.removeEventListener('resize', () => setPositionSelectList(isOpen.value));
  window.removeEventListener('scroll', () => setPositionSelectList(isOpen.value));
});

watch(
  () => props.modelValue,
  newValue => {
    currentValues.value = newValue;
    // Если включено множественное выбор, то фильтруем options, чтобы получить только выбранные значения
    if (props.isMultiply && Array.isArray(newValue)) {
      currentValueNames.value = props.options
        .filter(option => newValue.includes(option.value))
        .map(option => option.title);
    } else {
      // В противном случае ищем соответствующий заголовок для выбранного значения
      const selectedOption = props.options.find(option => option.value === newValue);
      currentValueNames.value = selectedOption ? selectedOption.title : null;
    }
  },
  { immediate: true },
);
</script>
