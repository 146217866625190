export function useCalculateAge(inputDate: string): string {
  const currentDate = new Date();
  const inputDateObject = new Date(inputDate);

  // Проверка, что введенная дата корректна
  if (isNaN(inputDateObject.getTime())) {
    return inputDate;
  }

  const timeDifference = currentDate.getTime() - inputDateObject.getTime();
  const ageInYears = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000));

  return `${ageInYears} лет`;
}
